import { useUpgradeFlightClass as useStore } from '../store';
import { useSelector } from 'react-redux';
import { getOrder } from '../../Checkout/store/order/selectors';
import { useCallback, useMemo } from 'react';
import { useRefundSummary } from '../../RefundSummary';
import { useSelectors } from '../store/selectors';
import { Log } from '../../sentry';
export var useUpgradeFlightClass = function (_a) {
    var onBackClick = _a.onBackClick, loading = _a.loading, onSaveServices = _a.onSaveServices, onContinueWithoutSelection = _a.onContinueWithoutSelection;
    var order = useSelector(getOrder);
    var _b = useStore(order), state = _b.state, dispatch = _b.dispatch;
    var selectors = useSelectors(state, order, onContinueWithoutSelection);
    var _c = useRefundSummary(selectors.refundSummaryParams), gdsServicesRefund = _c.gdsServicesRefund, refundServicesLoading = _c.loading;
    var handleToggleSegment = function (segmentId) { return dispatch({ type: 'toggleSegment', payload: segmentId }); };
    var handleSetStep = function (step) { return dispatch({ type: 'setStep', payload: step }); };
    var handleGoToReview = useCallback(function () {
        handleSetStep('review');
    }, [state.selectedSegments]);
    var handleConfirm = useCallback(function () {
        var params = selectors.saveOrderServicesVariables;
        onSaveServices(params);
    }, [selectors.saveOrderServicesVariables]);
    var toolbarCallbacks = useMemo(function () {
        if (state.step === 'select') {
            var nextStep = void 0;
            if (state.selectedSegments.length > 0) {
                nextStep = handleGoToReview;
            }
            else if (state.selectedSegments.length === 0 && onContinueWithoutSelection) {
                nextStep = onContinueWithoutSelection;
            }
            else {
                Log({
                    level: 'warning',
                    message: 'can not calculate next step'
                });
                nextStep = function () { return null; };
            }
            return {
                next: nextStep,
                back: onBackClick
            };
        }
        else {
            return {
                next: handleConfirm,
                back: function () { return handleSetStep('select'); }
            };
        }
    }, [state.step, handleGoToReview, handleConfirm, onContinueWithoutSelection]);
    return {
        order: order,
        step: state.step,
        transferDurationBySegments: selectors.transferDurationBySegments,
        refundServicesLoading: refundServicesLoading,
        gdsServicesRefund: gdsServicesRefund,
        toggleSegment: handleToggleSegment,
        service: state.service,
        flights: state.flights,
        selectedSegments: state.selectedSegments,
        nextButtonDisabled: selectors.nextButtonDisabled,
        isServicesSaving: loading,
        toolbarCallbacks: toolbarCallbacks,
        totalPrice: state.totalPrice
    };
};
