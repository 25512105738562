import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoDetails } from '../../../../../Icons';
import { useTheme } from '../../../../../theme';
import { useToggleable } from '../../../../../hooks';
import { useIsMobile } from '../../../../../utils';
import Button from '../../../../../Button';
import Conditions from './Conditions/Conditions';
import Modal from '../../../../../Modal';
import Close from '../../../../../CheckoutAdditionalService/components/Close/Close';
var ConditionsModal = function (_a) {
    var _b;
    var renderTrigger = _a.renderTrigger;
    var t = useTranslation('CheckinConditions').t;
    var theme = useTheme('Checkin').ConditionsModal;
    var _c = useToggleable(false), isOpen = _c.isOpen, open = _c.open, close = _c.close;
    var isMobile = useIsMobile();
    var renderClose = useCallback(function (onClick) { return React.createElement(Close, { className: theme.close, onClick: onClick }); }, []);
    return (React.createElement(React.Fragment, null, (_b = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger({ onClick: open })) !== null && _b !== void 0 ? _b : (React.createElement(Button, { variant: "text", className: theme.button, onClick: open },
        React.createElement("div", { className: theme.icon }, InfoDetails),
        t('Check-in conditions'))),
        React.createElement(Modal, { maxWidth: "sm", classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, open: isOpen, onClose: close, slideUp: isMobile, closeButtonRenderer: renderClose },
            React.createElement(Conditions, null))));
};
export default ConditionsModal;
