import { __assign } from "tslib";
import React, { useMemo } from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { LoginInputType } from '../../../types';
import Input from '../../../../Input';
import { detectInputType } from '../../../utils';
import { parsePhone } from '../../../../PhoneInput/utils';
var LoginInput = function (_a) {
    var input = _a.input, meta = _a.meta;
    var notValid = !!meta.error && meta.touched;
    var baseCss = useTheme('LoginPage').BaseComponent;
    var t = useTranslation('LoginPage').t;
    var value = useMemo(function () {
        var value = input.value;
        var type = detectInputType(value);
        if (type === LoginInputType.Phone) {
            var parserValue = parsePhone(value);
            value = parserValue ? parserValue : value;
        }
        return value;
    }, [input.value]);
    return (React.createElement(Input, { label: t('Email / Phone / Card number'), TextFieldProps: __assign(__assign({}, input), { value: value, error: notValid, helperText: notValid ? meta.error : '', InputProps: {
                classes: {
                    input: baseCss.input
                }
            } }) }));
};
export default LoginInput;
