import { __values } from "tslib";
import { useMemo } from 'react';
import { isCheckoutOrderGuard } from '../../typeguards';
export var useSelectors = function (state, order, onContinueWithoutSelection) {
    var saveOrderServicesVariables = useMemo(function () {
        var service = state.service;
        var travellers = state.travellersIds;
        var variables = {
            params: {
                id: state.orderId,
                services: travellers.map(function (travellerId) { return ({
                    travellerId: travellerId,
                    gdsServices: {
                        addServices: state.selectedSegments.map(function (segmentId) { return ({
                            segmentIds: [segmentId],
                            count: 1,
                            serviceId: service.id
                        }); })
                    }
                }); })
            }
        };
        return variables;
    }, [state.selectedSegments]);
    var transferDurationBySegments = useMemo(function () {
        var e_1, _a;
        var transferDurationBySegmentId = {};
        if (isCheckoutOrderGuard(order)) {
            try {
                for (var _b = __values(order.flight.segments), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var segment = _c.value;
                    transferDurationBySegmentId[segment.segment.id] = segment.transferDuration;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return transferDurationBySegmentId;
        }
        else {
            return {};
        }
    }, [order]);
    var refundSummaryParams = useMemo(function () {
        return {
            orderId: order.id,
            passengers: order.travellers,
            selectedPassengerIds: state.service.allowedPassengers.map(function (group) { return group[0]; }),
            selectedSegmentIds: state.selectedSegments,
            allFlightToExchange: isCheckoutOrderGuard(order)
                ? order.flight.segments.length === state.selectedSegments.length
                : order.segments.length === state.selectedSegments.length
        };
    }, [order, state]);
    var nextButtonDisabled = useMemo(function () {
        if (onContinueWithoutSelection) {
            return false;
        }
        return state.selectedSegments.length === 0;
    }, [onContinueWithoutSelection, state.selectedSegments]);
    return {
        transferDurationBySegments: transferDurationBySegments,
        saveOrderServicesVariables: saveOrderServicesVariables,
        refundSummaryParams: refundSummaryParams,
        nextButtonDisabled: nextButtonDisabled
    };
};
