import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { AviaPassengerType } from '@websky/graphql';
import { getPassengerAvatar, getPassengerLabel, TABLET_MIDDLE_WIDTH } from '../../../../utils';
import DesktopPassenger from './DesktopPassenger/DesktopPassenger';
import MobilePassenger from './MobilePassenger/MobilePassenger';
import { isFreeSeat as getIsFreeSeat } from '../../SeatMap/utils';
import { useTranslation } from 'react-i18next';
var Passenger = function (props) {
    var _a, _b, _c, _d;
    var t = useTranslation('SeatMap').t;
    var label = React.useMemo(function () { return getPassengerLabel(props.passenger); }, [props.passenger]);
    var avatarText = React.useMemo(function () { return getPassengerAvatar(props.passenger); }, [props.passenger]);
    var seatNumbers = ((_a = props === null || props === void 0 ? void 0 : props.seats) === null || _a === void 0 ? void 0 : _a.map(function (seat) { return seat.number; })) || [];
    var isDoubleSeats = seatNumbers.length > 1;
    var isFreeSeat = ((_b = props.seats) === null || _b === void 0 ? void 0 : _b.length) > 0 && props.seats.every(getIsFreeSeat);
    var isConfirmed = isDoubleSeats ? !props.isNewOrder : (_c = props === null || props === void 0 ? void 0 : props.seats) === null || _c === void 0 ? void 0 : _c.some(function (seat) { return seat.isConfirmed; });
    var isTablet = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    var disableReason = props.passenger.type === AviaPassengerType.INF &&
        t('Children under 2 years of age fly in the same seat with an adult');
    var onClickHandler = function () {
        if (!disableReason) {
            props.onClick(props.passenger.id);
        }
    };
    var onClearHandler = function () {
        props.onClear(props.passenger.id);
    };
    var PassengerComponent = isTablet ? MobilePassenger : DesktopPassenger;
    return (React.createElement(PassengerComponent, { isFreeSeat: isFreeSeat, passengerName: label, passengerAvatarText: avatarText, seatNumbers: seatNumbers, price: props === null || props === void 0 ? void 0 : props.seatsPrice, isActive: props.isActive, isActiveDoubleSeat: props.isActiveDoubleSeat, isDisabled: !!disableReason, recommend: props.recommend, confirmed: isConfirmed, onClick: onClickHandler, onClearSeat: onClearHandler, toggleExtraSeat: props.toggleExtraSeat, hasSeatsByPassenger: !!((_d = props.passenger.seats) === null || _d === void 0 ? void 0 : _d.length), showDoubleSeatInfo: props.segmentsCount > 1, tooltipTitle: disableReason }));
};
export default Passenger;
