import ym from 'react-yandex-metrika';
export var DATA_LAYER_KEY = 'dataLayer';
export var MotivationGoal;
(function (MotivationGoal) {
    // Seats
    MotivationGoal["ConfirmProposedSeat"] = "motivation-proposedSeat-agree";
    MotivationGoal["RefuseProposedSeat"] = "motivation-proposedSeat-disagree";
    MotivationGoal["ConfirmToChooseSeat"] = "motivation-chooseSeat-agree";
    MotivationGoal["RefuseToChooseSeat"] = "motivation-chooseSeat-disagree";
    // Baggage
    // ConfirmProposedBaggage = 'motivation-proposedBaggage-agree',
    // RefuseProposedBaggage = 'motivation-proposedBaggage-disagree',
    // Meal
    MotivationGoal["ConfirmProposedMeal"] = "motivation-proposedMeal-agree";
    MotivationGoal["RefuseProposedMeal"] = "motivation-proposedMeal-disagree";
})(MotivationGoal || (MotivationGoal = {}));
export var OrderGoal;
(function (OrderGoal) {
    OrderGoal["Book"] = "order-book";
    OrderGoal["BookingError"] = "order-booking-error";
    OrderGoal["Booked"] = "order-booked";
    OrderGoal["Paid"] = "order-paid";
    OrderGoal["PaymentFailed"] = "order-payment-failed";
    OrderGoal["ItineraryReceiptDownload"] = "order-itinerary-receipt-download";
})(OrderGoal || (OrderGoal = {}));
export var UserGoal;
(function (UserGoal) {
    UserGoal["Login"] = "user-login";
    UserGoal["Registration"] = "user-registration";
    UserGoal["ContinueOnDesktop"] = "continue-on-desktop";
    UserGoal["BindOrder"] = "bind-order";
})(UserGoal || (UserGoal = {}));
export var CheckinGoal;
(function (CheckinGoal) {
    CheckinGoal["Create"] = "create-checkin";
    CheckinGoal["Start"] = "start-checkin";
    CheckinGoal["CompleteWithoutPayment"] = "checkin-complete-without-payment";
    CheckinGoal["CompleteWithPayment"] = "checkin-complete-with-payment";
    CheckinGoal["Paid"] = "checkin-paid";
    CheckinGoal["PaymentCancelled"] = "checkin-payment-cancelled";
    CheckinGoal["Cancelled"] = "checkin-cancelled";
    CheckinGoal["AddTraveller"] = "checkin-add-traveller";
    CheckinGoal["RemoveTraveller"] = "checkin-remove-traveller";
    CheckinGoal["ResendTicket"] = "checkin-resend-ticket";
    CheckinGoal["AppleWalletPassDownload"] = "checkin-apple-wallet-pass-download";
    CheckinGoal["BoardingPassDownload"] = "checkin-boarding-pass-download";
})(CheckinGoal || (CheckinGoal = {}));
export var SplitTestGoal;
(function (SplitTestGoal) {
    SplitTestGoal["RefusedToAuthorize"] = "test-refused-to-authorize";
    SplitTestGoal["AgreedToAuthorize"] = "test-agreed-to-authorize";
})(SplitTestGoal || (SplitTestGoal = {}));
export var InsuranceGoal;
(function (InsuranceGoal) {
    InsuranceGoal["InsurancePopupYes"] = "insurance_popup_yes";
    InsuranceGoal["InsurancePopupNo"] = "insurance_popup_no";
    InsuranceGoal["PurchaseWithInsurance"] = "purchase_with_insurance_";
})(InsuranceGoal || (InsuranceGoal = {}));
export var SplitTest;
(function (SplitTest) {
    // AskUserToAuthorize = 'ask-user-to-authorize', // complete
    SplitTest["OneButtonBaggageSelector"] = "one-button-baggage-selector";
    SplitTest["SkipServicesSteps"] = "skip-services-steps";
    SplitTest["SkipServicesRecommendations"] = "skip-services-recommendations";
})(SplitTest || (SplitTest = {}));
export var SearchGoal;
(function (SearchGoal) {
    SearchGoal["SuccessfulSearchWithHotels"] = "successful_search_with_hotels";
    SearchGoal["SuccessfulSearchWithoutHotels"] = "successful_search_without_hotels";
})(SearchGoal || (SearchGoal = {}));
export var ActionType;
(function (ActionType) {
    ActionType["Detail"] = "detail";
    ActionType["Add"] = "add";
    ActionType["Remove"] = "remove";
    ActionType["Purchase"] = "purchase";
})(ActionType || (ActionType = {}));
export var ProductType;
(function (ProductType) {
    ProductType["FareFamily"] = "Fare Family";
    ProductType["Order"] = "Order";
    ProductType["Insurance"] = "Insurance";
    ProductType["Transfer"] = "Transfer";
    ProductType["Baggage"] = "Baggage";
    ProductType["Meal"] = "Meal";
    ProductType["Seat"] = "Seat";
})(ProductType || (ProductType = {}));
export var TransportLegType;
(function (TransportLegType) {
    TransportLegType["Direct"] = "direct";
    TransportLegType["Transfer"] = "transfer";
})(TransportLegType || (TransportLegType = {}));
export var reachGoal = function (goal, parameters) {
    if (process.env.NODE_ENV === 'production') {
        try {
            ym('reachGoal', goal, parameters);
        }
        catch (e) {
            console.warn('YM reachGoal failed');
        }
    }
};
export var setSplitTest = function (testName, value) {
    var _a;
    if (process.env.NODE_ENV === 'production') {
        try {
            ym('params', (_a = {},
                _a["test-".concat(testName)] = value,
                _a));
        }
        catch (e) {
            console.warn('YM is not initialized');
        }
    }
};
