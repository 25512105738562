import { __assign } from "tslib";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ModeContext, useConfig } from '../context';
import { getAnimalServices, getTravellers } from '../Checkout/store/order/selectors';
import { getPriceToPay } from '../Checkout/store/orderPrice/selectors';
import { getAnimalTotalPrice } from '../CheckoutAdditionalService/components/utils';
import { Mode } from '../types';
import { useContext } from 'react';
import { getPriceToPay as checkinGetPriceToPay, getTravellers as checkinGetTravellers } from '../Modules/Checkin/store/order/selectors';
export var useCartPrices = function () {
    var servicesAvailable = useConfig().global.servicesAvailable;
    var mode = useContext(ModeContext);
    var priceToPay;
    var travellers;
    var animalServices;
    if (mode === Mode.Checkin) {
        priceToPay = useSelector(checkinGetPriceToPay);
        travellers = useSelector(checkinGetTravellers);
        animalServices = useSelector(getAnimalServices);
    }
    else {
        priceToPay = useSelector(getPriceToPay);
        travellers = useSelector(getTravellers);
        animalServices = useSelector(getAnimalServices);
    }
    return React.useMemo(function () {
        var animalsPrice = null;
        var baggagePrice = __assign({}, priceToPay.baggage);
        if (servicesAvailable.animal) {
            animalsPrice = getAnimalTotalPrice(animalServices, travellers, true);
        }
        if (animalsPrice && baggagePrice.amount >= animalsPrice.amount) {
            baggagePrice = __assign(__assign({}, baggagePrice), { amount: baggagePrice.amount - animalsPrice.amount });
        }
        if (baggagePrice.amount === 0) {
            baggagePrice = null;
        }
        return __assign(__assign({}, priceToPay), { baggage: baggagePrice, animals: animalsPrice });
    }, [priceToPay, travellers, animalServices]);
};
