import { AviaPassengerType } from '@websky/graphql';
export var convertScheduleSearchParams = function (parameters, date) { return ({
    passengers: [
        {
            passengerType: AviaPassengerType.ADT,
            count: 1
        }
    ],
    segments: parameters.segments.map(function (segment) { return ({
        departure: {
            iata: segment.departure.iata
        },
        arrival: {
            iata: segment.arrival.iata
        },
        date: date
    }); })
}); };
