import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { CartService } from '../../Cart/types';
import CartItemOptionAdapter from '../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import ServiceSummaryDivider from '../components/ServiceSummaryDivider/ServiceSummaryDivider';
import { groupServices } from '../../Cart/utils';
import { getActualProduct } from '../utils';
var VipSummary = function (props) {
    var onDeleteHandler = function (serviceId, passengerId, segmentId, allSegments) { return function () {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.BusinessLounge, {
            type: OrderAdditionalServiceGdsServiceServiceType.Vip,
            serviceId: serviceId,
            passengerId: passengerId,
            segmentId: segmentId,
            allSegments: allSegments
        });
    }; };
    var serviceFilter = React.useCallback(function (service) {
        var vipService = service;
        return vipService.subType === props.serviceSubType;
    }, [props.serviceSubType]);
    var vipServices = React.useMemo(function () {
        var allSegmentsOptions = [];
        var otherSegmentsOptions = [];
        props.travellers.forEach(function (traveller) {
            var _a;
            var groupedService = groupServices(props.services, traveller, traveller.id, OrderAdditionalServiceGdsServiceServiceType.Vip, props.flight.segments, undefined, serviceFilter);
            Object.keys((_a = groupedService[traveller.id]) !== null && _a !== void 0 ? _a : {}).forEach(function (segment) {
                var _a, _b;
                var items = [];
                var isAllSegments = segment === 'allSegments';
                (_b = (_a = groupedService[traveller.id][segment]) === null || _a === void 0 ? void 0 : _a.additional) === null || _b === void 0 ? void 0 : _b.forEach(function (additional) {
                    var _a;
                    var pricesMap = new Set(((_a = additional.prices) === null || _a === void 0 ? void 0 : _a.map(function (price) { return price.amount; })) || []);
                    var isDifferentPrices = pricesMap.size > 1;
                    var actualAmount = isDifferentPrices ? Math.min.apply(Math, __spreadArray([], __read(pricesMap), false)) : additional.service.price.amount;
                    var actualProduct = getActualProduct(additional.products);
                    items.push({
                        id: additional.serviceId,
                        name: props.serviceName,
                        price: __assign(__assign({}, additional.service.price), { amount: actualAmount }),
                        priceFrom: isDifferentPrices,
                        status: actualProduct === null || actualProduct === void 0 ? void 0 : actualProduct.status,
                        onDelete: props.onServiceDelete
                            ? onDeleteHandler(additional.serviceId, traveller.id, segment, isAllSegments)
                            : null
                    });
                });
                if (!items.length) {
                    return;
                }
                if (isAllSegments) {
                    allSegmentsOptions.push({
                        items: items,
                        allSegments: true,
                        headerType: 'travellerName',
                        traveller: props.travellersById.get(traveller.id)
                    });
                }
                else {
                    var selectedSegment = props.flight.segments.find(function (seg) { return seg.segment.id === segment; });
                    otherSegmentsOptions.push({
                        items: items,
                        direction: selectedSegment.segment,
                        headerType: 'travellerName',
                        traveller: props.travellersById.get(traveller.id)
                    });
                }
            });
        });
        return {
            allSegmentsOptions: allSegmentsOptions,
            otherSegmentsOptions: otherSegmentsOptions
        };
    }, [props.flight, props.travellers, props.services, serviceFilter]);
    var content = React.useMemo(function () {
        if (!vipServices.allSegmentsOptions.length && !vipServices.otherSegmentsOptions.length) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            !!vipServices.allSegmentsOptions.length && (React.createElement(ServiceSummaryDivider, null, vipServices.allSegmentsOptions.map(function (group) { return (React.createElement(CartItemOptionAdapter, { option: group, key: group.header })); }))),
            !!vipServices.otherSegmentsOptions.length && (React.createElement(ServiceSummaryDivider, null, vipServices.otherSegmentsOptions.map(function (group) { return (React.createElement(CartItemOptionAdapter, { option: group, key: group.header })); })))));
    }, [vipServices]);
    return props.children(content);
};
export default VipSummary;
