import { __assign, __read, __spreadArray, __values } from "tslib";
import { createSelector } from 'reselect';
import { convertBaggageObject, convertMealObject } from '../../types';
import { get, MEAL_RECOMMENDATION_SHOWN } from '../../../cache';
import { Measurement } from '../../../BaggageService/types';
import { getGdsServiceMinPrice, getIsBrandIncludedService } from '../../utils';
import { parseIncludedBaggage } from '../../../BaggagePage/utils';
import { getHasRequestedServices, isOdd } from '../../../utils';
import { getHasSelectedSeats, getPassengerStateCompatible } from '../selectedServices/selectors';
import { useConfig } from '../../../context';
import { AviaPassengerType, BaggageType, GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType, OrderStatus, PaymentStatusEnum } from '@websky/graphql';
import { getRelatedSegments } from '../../../Baggage/UpgradeBaggageDialog/utils';
var getState = function (state) { return state; };
export var getOrder = createSelector(getState, function (state) { return state.order; });
export var getShowServicesBeforeBooking = createSelector(getOrder, function (order) { var _a; return (_a = order.showServicesBeforeBooking) !== null && _a !== void 0 ? _a : true; });
export var getIsBlockedForPayment = createSelector(getOrder, function (order) { return order.isBlockedForPayment; });
export var getServices = createSelector(getOrder, function (order) { var _a, _b; return (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) !== null && _b !== void 0 ? _b : []; });
export var getRecheckAvailabilityTimout = createSelector(getOrder, function (order) { return order.timeoutToReCheckAvailability; });
export var getActualizationResult = createSelector(getOrder, function (order) { return order.actualizationResult; });
export var getTravellers = createSelector(getOrder, function (order) { return order.travellers; });
export var getBonusForFlight = createSelector(getOrder, function (order) { var _a; return (_a = order.ffpInfo) === null || _a === void 0 ? void 0 : _a.bonusForFlight; });
export var getPriceInMiles = createSelector(getOrder, function (order) { var _a; return (_a = order.price) === null || _a === void 0 ? void 0 : _a.totalInMiles; });
export var getCustomerSubscribed = createSelector(getOrder, function (order) { return order.customer.subscribed; });
export var getAvailableServices = createSelector(getServices, function (services) {
    var availableGdsServices = {};
    services.forEach(function (service) {
        var serviceType = service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage
            ? service.baggageType
            : service.type;
        if (!availableGdsServices.hasOwnProperty(serviceType)) {
            availableGdsServices[serviceType] = serviceType;
        }
    });
    return availableGdsServices;
});
export var getSegmentGroups = createSelector(getOrder, function (order) { var _a; return (_a = order.flight) === null || _a === void 0 ? void 0 : _a.segmentGroups; });
export var getFareFamiliesMap = createSelector(getSegmentGroups, function (segmentGroups) {
    var fareFamiliesMap = new Map();
    segmentGroups.forEach(function (segmentGroup) {
        var fareFamily = segmentGroup.fareFamily;
        if (!fareFamily) {
            return;
        }
        if (!fareFamiliesMap.has(fareFamily.id)) {
            fareFamiliesMap.set(fareFamily.id, {
                fareFamily: fareFamily,
                segmentIds: []
            });
        }
        segmentGroup.segments.forEach(function (segment) { return fareFamiliesMap.get(fareFamily.id).segmentIds.push(segment.id); });
    });
    return fareFamiliesMap;
});
export var getIsOrderBlockedForPayment = createSelector(getOrder, function (order) { return order.isBlockedForPayment; });
export var getOldOrderPrice = createSelector(getOrder, function (order) { var _a; return (_a = order === null || order === void 0 ? void 0 : order.actualizationResult) === null || _a === void 0 ? void 0 : _a.oldPrice; });
export var getNewOrderPrice = createSelector(getOrder, function (order) { var _a; return (_a = order === null || order === void 0 ? void 0 : order.actualizationResult) === null || _a === void 0 ? void 0 : _a.newPrice; });
export var getFareLockService = createSelector(getServices, function (services) {
    return services.find(function (service) { return service.isPriceFreeze; });
});
export var getIsFareLockSelected = createSelector(getTravellers, getFareLockService, function (travellers, fareLockService) {
    return travellers === null || travellers === void 0 ? void 0 : travellers.some(function (traveller) { var _a, _b, _c; return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) { return (service === null || service === void 0 ? void 0 : service.serviceId) === (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.id); }); });
});
export var getIsFareLockConfirmed = createSelector(getTravellers, getFareLockService, function (travellers, fareLockService) {
    return travellers === null || travellers === void 0 ? void 0 : travellers.some(function (traveller) {
        var _a, _b, _c;
        return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) { return (service === null || service === void 0 ? void 0 : service.serviceId) === (fareLockService === null || fareLockService === void 0 ? void 0 : fareLockService.id) && (service === null || service === void 0 ? void 0 : service.confirmedCount); });
    });
});
export var getIsPromoCodeAppliedToFlight = createSelector(getOldOrderPrice, getNewOrderPrice, function (oldPrice, newPrice) { return (newPrice === null || newPrice === void 0 ? void 0 : newPrice.amount) < (oldPrice === null || oldPrice === void 0 ? void 0 : oldPrice.amount); });
var _getIncludedBaggage = createSelector(getFareFamiliesMap, getTravellers, useConfig, function (fareFamiliesMap, travellers, config) {
    var _a, _b;
    if (['N4', 'DV', 'YC'].includes((_b = (_a = config === null || config === void 0 ? void 0 : config.global) === null || _a === void 0 ? void 0 : _a.companyInfo) === null || _b === void 0 ? void 0 : _b.iataCode)) {
        // filter out all included baggage for INF and INS
        var travellersWithoutInfants = travellers.filter(function (traveller) { return traveller.type !== AviaPassengerType.INF && traveller.type !== AviaPassengerType.INS; });
        return fareFamiliesMap
            ? parseIncludedBaggage(fareFamiliesMap, travellersWithoutInfants.map(function (_a) {
                var id = _a.id;
                return id;
            }))
            : [];
    }
    return fareFamiliesMap
        ? parseIncludedBaggage(fareFamiliesMap, travellers.map(function (_a) {
            var id = _a.id;
            return id;
        }))
        : [];
});
export var getBrandIncludedBaggage = createSelector(getTravellers, function (travellers) {
    var includedBaggages = [];
    travellers.forEach(function (traveller) {
        var _a, _b;
        return (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.brandIncludedServices) === null || _b === void 0 ? void 0 : _b.services.filter(function (services) { return services.service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage; }).forEach(function (service) {
            var _a;
            var baggageService = service.service;
            var baggage = {
                id: service.serviceId,
                segmentIds: baggageService.allowedSegments,
                includedSegments: service.segmentIds,
                type: baggageService.baggageType,
                name: baggageService.name,
                description: baggageService.description,
                value: {
                    amount: parseInt((_a = baggageService.baggageWeight) === null || _a === void 0 ? void 0 : _a.split('x')[0]),
                    measurement: Measurement.Kilograms
                },
                size: baggageService.size,
                included: service.confirmedCount,
                price: baggageService.price,
                rfics: baggageService.rfisc,
                canBeAdded: false,
                allowedPassengers: [traveller.id],
                isReplacedBy: baggageService.isReplacedBy
            };
            includedBaggages.push(__assign(__assign({}, service), { passengerId: traveller.id, baggage: baggage }));
        });
    });
    return includedBaggages;
});
export var getAnimalServices = createSelector(getServices, function (services) {
    return services.filter(function (service) {
        return [BaggageType.PetInCabin, BaggageType.PetInHold, BaggageType.PetInHandCarrier].includes(service.baggageType);
    });
});
export var hasSeatsServices = createSelector(getServices, function (services) {
    return services.some(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Seat; });
});
export var hasBaggageServices = createSelector(getServices, function (services) {
    return services.some(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage; });
});
export var getSeatMap = createSelector(getOrder, function (order) { return order.flight.seatMap; });
export var getOrderId = createSelector(getOrder, function (order) { return order.id; });
export var getIsOddOrderId = createSelector(getOrderId, isOdd);
export var getIsNew = createSelector(getOrder, function (order) { return order.status === OrderStatus.New; });
export var getIsNotNew = createSelector(getOrder, function (order) { return order.status !== OrderStatus.New; });
export var getIsTravellersDataFilled = createSelector(getOrder, function (order) { return order.isTravellersDataFilled; });
export var getIsReadonly = createSelector(getIsTravellersDataFilled, getIsNew, function (isTravellersDataFilled, isNew) { return isTravellersDataFilled && !isNew; });
export var getIsRedirectFromMetasearch = createSelector(getOrder, function (order) { return order.redirectFromMetasearch; });
export var getOrderStatus = createSelector(getOrder, function (order) { return order.status; });
export var getHasAvailableSeats = createSelector(getSeatMap, function (seatMap) { var _a; return (_a = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments) === null || _a === void 0 ? void 0 : _a.some(function (_a) {
    var availableSeatsCount = _a.availableSeatsCount;
    return availableSeatsCount > 0;
}); });
export var getIsAllSegmentsAvailableOnlyOnCheckin = createSelector(getSeatMap, function (seatMap) {
    return seatMap.segments.every(function (segment) { return segment.isSeatsAvailableInCheckinOnly === true; });
});
export var getIsBookedOrConfirmed = createSelector(getOrder, function (order) { return order.status === OrderStatus.Booked || order.status === OrderStatus.Confirmed; });
export var getTotalInsurancePrice = createSelector(getOrder, function (state) { return state.price.insurance; });
export var getIsBooked = createSelector(getOrder, function (order) { return order.status === OrderStatus.Booked; });
export var getIsConfirmed = createSelector(getOrder, function (order) { return order.status === OrderStatus.Confirmed; });
export var getIsConfirmedOrCancelled = createSelector(getOrder, function (order) { return order.status === OrderStatus.Confirmed || order.status === OrderStatus.Cancelled; });
export var getIsPriceFreezeProductSelected = createSelector(getOrder, function (order) { return order.isPriceFreezeProductSelected; });
export var getIsCancelled = createSelector(getOrder, function (order) { return order.status === OrderStatus.Cancelled; });
export var getIsFFPUsed = createSelector(getOrder, function (order) { return !!order.ffpInfo; });
export var getMilesForOrder = createSelector(getOrder, function (order) { var _a; return (_a = order.ffpInfo) === null || _a === void 0 ? void 0 : _a.bonusForFlight; });
export var getAvailableGdsServicesByType = createSelector(getOrder, getSeatMap, getHasSelectedSeats, function (order, seatMap, hasSelectedSeats) {
    var _a, _b, _c;
    var _d, _e, _f, _g;
    var availableServices = {};
    (_f = (_e = (_d = order.additionalServices) === null || _d === void 0 ? void 0 : _d.gdsServices) === null || _e === void 0 ? void 0 : _e.services) === null || _f === void 0 ? void 0 : _f.forEach(function (service) {
        // TODO: check that service.canBeAdded = true when php fix it
        if (!availableServices.hasOwnProperty(service.type)) {
            availableServices[service.type] = true;
        }
    });
    return __assign(__assign(__assign(__assign({}, availableServices), (_a = {},
        _a[OrderAdditionalServiceGdsServiceServiceType.Seat] = ((_g = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments) === null || _g === void 0 ? void 0 : _g.some(function (segment) { return segment.availableSeatsCount > 0 || segment.isSeatsAvailableInCheckinOnly; })) || hasSelectedSeats,
        _a)), (_b = {},
        _b[OrderAdditionalServiceGdsServiceServiceType.Baggage] = availableServices.Baggage ||
            getIsBrandIncludedService(order, OrderAdditionalServiceGdsServiceServiceType.Baggage),
        _b)), (_c = {},
        _c[OrderAdditionalServiceGdsServiceServiceType.Meal] = availableServices.Meal ||
            getIsBrandIncludedService(order, OrderAdditionalServiceGdsServiceServiceType.Meal),
        _c));
});
export var getPaymentIsRequired = createSelector(getOrder, function (order) { return order.paymentStatus !== PaymentStatusEnum.Full; });
export var getCustomer = createSelector(getOrder, function (order) { return order === null || order === void 0 ? void 0 : order.customer; });
export var getIsContactsInfoIsEmpty = createSelector(getCustomer, function (customer) { return customer.contactInfoIsEmpty; });
export var isRoundTrip = createSelector(getOrder, function (order) {
    var segmentGroups = order.flight.segmentGroups;
    if (segmentGroups.length !== 2) {
        return false;
    }
    var outbound = segmentGroups[0];
    var outboundDeparture = outbound.segments[0].departure.airport.iata;
    var outboundArrival = outbound.segments[outbound.segments.length - 1].arrival.airport.iata;
    var inbound = segmentGroups[1];
    var inboundDeparture = inbound.segments[0].departure.airport.iata;
    var inboundArrival = inbound.segments[inbound.segments.length - 1].arrival.airport.iata;
    return outboundArrival === inboundDeparture && outboundDeparture === inboundArrival;
});
export var getSegmentsForServices = createSelector(getOrder, function (order) {
    var segments = [];
    order.flight.segmentGroups.map(function (group) {
        return group.segments.map(function (segment) {
            var _a;
            segments.push({
                id: segment.id,
                arrival: segment.arrival,
                departure: segment.departure,
                amenities: (_a = segment.flightInfo) === null || _a === void 0 ? void 0 : _a.amenities,
                groupId: group.groupId
            });
        });
    });
    return segments;
});
export var getSegmentsForBaggage = createSelector(getOrder, function (order) {
    return order.flight.segments.map(function (seg) { return ({
        id: seg.segment.id,
        departure: seg.segment.departure,
        arrival: seg.segment.arrival
    }); });
});
export var getSegments = createSelector(getOrder, function (order) {
    return order.flight.segments.map(function (segment) { return segment.segment; });
});
export var getSegmentIds = createSelector(getOrder, function (order) {
    return order.flight.segments.map(function (segment) { return segment.segment.id; });
});
export var getMessages = createSelector(getOrder, function (order) { return order.messages; });
export var getBaggageMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage);
});
export var getGroundTransportationServices = createSelector(getServices, function (services) {
    return services.filter(function (service) {
        return service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
            service.subType ===
                GdsServiceVipSubType.GroundTransportation;
    });
});
export var getUpgradeBaggageMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage, [BaggageType.HandLuggageExcess]);
});
export var getSportsEquipmentMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage, [
        BaggageType.SportingEquipment,
        BaggageType.PetInHold,
        BaggageType.PetInCabin,
        BaggageType.MusicalInstrument,
        BaggageType.FishingEquipment,
        BaggageType.SurfingEquipment,
        BaggageType.Ski,
        BaggageType.Bike,
        BaggageType.PetInHandCarrier
    ]);
});
export var getOverweightMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Baggage, [BaggageType.BaggageExcess]);
});
export var getCurrencyFromOrder = createSelector(getOrder, function (order) { var _a; return (_a = order.price.total) === null || _a === void 0 ? void 0 : _a.currency; });
export var getSeatsMinPrice = createSelector(getOrder, function (order) {
    var _a, _b, _c, _d, _e;
    var minPrice = 0;
    var currency = (_c = (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(function (service) { var _a; return !!((_a = service === null || service === void 0 ? void 0 : service.price) === null || _a === void 0 ? void 0 : _a.currency); })) === null || _c === void 0 ? void 0 : _c.price.currency;
    (_e = (_d = order.flight.seatMap) === null || _d === void 0 ? void 0 : _d.segments) === null || _e === void 0 ? void 0 : _e.forEach(function (segment) {
        var _a;
        (_a = segment === null || segment === void 0 ? void 0 : segment.decks) === null || _a === void 0 ? void 0 : _a.forEach(function (deck) {
            var _a;
            (_a = deck === null || deck === void 0 ? void 0 : deck.rows) === null || _a === void 0 ? void 0 : _a.forEach(function (row) {
                row === null || row === void 0 ? void 0 : row.seats.forEach(function (seat) {
                    var _a, _b;
                    if (!(seat === null || seat === void 0 ? void 0 : seat.isAvailable)) {
                        return;
                    }
                    if (!minPrice) {
                        minPrice = (_a = seat === null || seat === void 0 ? void 0 : seat.price) === null || _a === void 0 ? void 0 : _a.amount;
                    }
                    if (((_b = seat === null || seat === void 0 ? void 0 : seat.price) === null || _b === void 0 ? void 0 : _b.amount) < minPrice) {
                        minPrice = seat.price.amount;
                    }
                });
            });
        });
    });
    return {
        currency: currency,
        amount: minPrice
    };
});
export var getMealMinPrice = createSelector(getOrder, function (order) {
    return getGdsServiceMinPrice(order, OrderAdditionalServiceGdsServiceServiceType.Meal);
});
// #todo: add tests
export var getSearchParameters = createSelector(getOrder, getCurrencyFromOrder, function (_a, orderCurrency) {
    var flight = _a.flight, travellers = _a.travellers;
    var passengersMap = {};
    var passengers = [];
    travellers.forEach(function (_a) {
        var type = _a.type;
        if (passengersMap.hasOwnProperty(type)) {
            passengersMap[type]++;
        }
        else {
            passengersMap[type] = 1;
        }
    });
    for (var passengerType in passengersMap) {
        if (passengersMap.hasOwnProperty(passengerType)) {
            passengers.push({
                passengerType: passengerType,
                extendedPassengerType: null,
                count: passengersMap[passengerType]
            });
        }
    }
    return {
        promotionCode: null,
        currency: orderCurrency,
        passengers: passengers,
        segments: flight.segmentGroups.map(function (group) {
            var firstSegment = group.segments[0];
            var lastSegment = group.segments[group.segments.length - 1];
            return {
                date: firstSegment.departure.date,
                departure: {
                    name: firstSegment.departure.airport.name,
                    iata: firstSegment.departure.airport.iata,
                    city: {
                        name: firstSegment.departure.airport.city.name
                    }
                },
                arrival: {
                    name: lastSegment.arrival.airport.name,
                    iata: lastSegment.arrival.airport.iata,
                    city: {
                        name: lastSegment.arrival.airport.city.name
                    }
                }
            };
        }),
        ffpMode: false
    };
});
// check that canAddServices is boolean ( because in CheckinOrder there is no such props and shouldRedirectToFallback always returns true )
export var shouldRedirectToFallback = createSelector(getOrder, function (_a) {
    var canAddServices = _a.canAddServices, canRemoveServices = _a.canRemoveServices;
    return typeof canAddServices === 'boolean' &&
        typeof canRemoveServices === 'boolean' &&
        (!canAddServices || !canRemoveServices);
});
export var getServicesFallbackURL = createSelector(getOrder, function (_a) {
    var servicesFallbackURL = _a.servicesFallbackURL;
    return servicesFallbackURL;
});
export var getAeroexpress = createSelector(getOrder, function (order) { var _a, _b; return (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.aeroexpress; });
export var getInsurances = createSelector(getOrder, function (order) { var _a; return (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances; });
export var getInsurancesGroups = createSelector(getInsurances, function (insurances) { return insurances === null || insurances === void 0 ? void 0 : insurances.insuranceProgramGroups; });
export var getInsurancesByGroups = createSelector(getInsurances, getInsurancesGroups, function (insurances, groups) {
    var _a;
    if (!((_a = insurances === null || insurances === void 0 ? void 0 : insurances.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length)) {
        return [];
    }
    if (!(groups === null || groups === void 0 ? void 0 : groups.length)) {
        return [{ groupName: null, insurancePrograms: insurances.insurancePrograms }];
    }
    return groups.map(function (group) { return ({
        groupName: group.groupValue,
        insurancePrograms: insurances.insurancePrograms.filter(function (program) {
            return group.insurancePrograms.some(function (_a) {
                var code = _a.code;
                return code === program.code;
            });
        })
    }); });
});
export var getAvailableInsuranceCodes = createSelector(getInsurances, function (insurance) {
    var _a;
    var availableInsuranceCodeSet = new Set();
    if ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) {
        insurance.insurancePrograms.forEach(function (insurance) { return availableInsuranceCodeSet.add(insurance.code); });
    }
    return __spreadArray([], __read(availableInsuranceCodeSet), false);
});
export var getSelectedInsuranceCodes = createSelector(getInsurances, function (insurance) {
    var _a;
    var selectedInsuranceCodeSet = new Set();
    if ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.selectedInsurances) === null || _a === void 0 ? void 0 : _a.length) {
        insurance.selectedInsurances.forEach(function (insurance) {
            return selectedInsuranceCodeSet.add(insurance.insuranceProgram.code);
        });
    }
    return __spreadArray([], __read(selectedInsuranceCodeSet), false);
});
export var getImportantInsuranceCodes = createSelector(getInsurances, function (insurance) {
    var _a;
    var importantInsuranceCodeSet = new Set();
    if ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) {
        insurance.insurancePrograms.forEach(function (insurance) {
            if (insurance.important) {
                importantInsuranceCodeSet.add(insurance.code);
            }
        });
    }
    return __spreadArray([], __read(importantInsuranceCodeSet), false);
});
export var getAvailableInsuranceCodesByPrograms = function (insurancePrograms) {
    return createSelector(getAvailableInsuranceCodes, function (availableCodes) {
        if (!(insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length)) {
            return availableCodes;
        }
        return availableCodes.filter(function (code) { return insurancePrograms.some(function (program) { return program.code === code; }); });
    });
};
export var getAvailableSelectedInsuranceCodes = function (availableCodes) {
    return createSelector(getSelectedInsuranceCodes, function (selectedCodes) {
        if (!(availableCodes === null || availableCodes === void 0 ? void 0 : availableCodes.length)) {
            return selectedCodes;
        }
        return selectedCodes.filter(function (code) { return !availableCodes.includes(code); });
    });
};
export var getInsuranceCodeBySalePriority = createSelector(getInsurances, function (insurance) {
    var _a;
    var insuranceCodeBySalePriorityMap = new Map();
    if ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) {
        insurance.insurancePrograms.forEach(function (insuranceProgram) {
            var _a, _b;
            var salePriority = (_b = (_a = insuranceProgram.extraParams) === null || _a === void 0 ? void 0 : _a.find(function (param) { return param.key === 'salePriority'; })) === null || _b === void 0 ? void 0 : _b.value;
            if (salePriority) {
                insuranceCodeBySalePriorityMap.set(insuranceProgram.code, +salePriority);
            }
        });
    }
    return insuranceCodeBySalePriorityMap;
});
export var getSortedPriorityInsurancePrograms = createSelector(getInsurances, getInsuranceCodeBySalePriority, function (insurance, insuranceCodeBySalePriority) {
    var _a;
    if (!((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) || !insuranceCodeBySalePriority.size) {
        return [];
    }
    return __spreadArray([], __read(insurance.insurancePrograms), false).filter(function (insuranceProgram) { return insuranceCodeBySalePriority.has(insuranceProgram.code); })
        .sort(function (a, b) {
        var _a, _b;
        var aPriority = (_a = insuranceCodeBySalePriority.get(a.code)) !== null && _a !== void 0 ? _a : 0;
        var bPriority = (_b = insuranceCodeBySalePriority.get(b.code)) !== null && _b !== void 0 ? _b : 0;
        return bPriority - aPriority;
    });
});
export var getPriorityInsuranceProgram = createSelector(useConfig, getSelectedInsuranceCodes, getSortedPriorityInsurancePrograms, function (config, selectedInsuranceCodes, sortedPriorityInsurances) {
    if (config.global.insurance.multiplePriorityInsurances) {
        return sortedPriorityInsurances.filter(function (insurance) { return !selectedInsuranceCodes.includes(insurance.code); })[0];
    }
    return sortedPriorityInsurances[0];
});
function singleInsuranceProgramFilter(insuranceProgram, inversion) {
    return insuranceProgram.important !== inversion;
}
export var getSingleInsurancePrograms = function (inversion) {
    if (inversion === void 0) { inversion = false; }
    return createSelector(getInsurances, function (services) { var _a, _b; return (_b = (_a = services === null || services === void 0 ? void 0 : services.insurancePrograms) === null || _a === void 0 ? void 0 : _a.filter(function (i) { return singleInsuranceProgramFilter(i, inversion); })) !== null && _b !== void 0 ? _b : []; });
};
export var getSelectedSingleInsurances = function (inversion) {
    if (inversion === void 0) { inversion = false; }
    return createSelector(getInsurances, function (services) {
        return services.selectedInsurances.filter(function (i) { return singleInsuranceProgramFilter(i.insuranceProgram, inversion); });
    });
};
export var getMealServices = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Meal; });
});
export var getBaggageServices = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage; });
});
export var getVipServices = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.type === OrderAdditionalServiceGdsServiceServiceType.Vip; });
});
export var getMeals = createSelector(getMealServices, function (meals) { return meals.map(convertMealObject); });
export var getBaggage = createSelector(getBaggageServices, function (baggage) { return baggage.map(convertBaggageObject); });
export var getUpgradeClassServices = createSelector(getVipServices, function (vipServices) { var _a; return (_a = vipServices.filter(function (service) { return service.subType === GdsServiceVipSubType.Upgrade; })) !== null && _a !== void 0 ? _a : []; });
export var getIsUpgradeClassServiceSelected = createSelector(getUpgradeClassServices, getTravellers, function (upgradeServices, travellers) {
    var upgradeServicesIds = upgradeServices.map(function (_a) {
        var id = _a.id;
        return id;
    });
    return travellers.some(function (traveller) {
        var _a, _b, _c;
        return (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(function (service) {
            return ((upgradeServicesIds.includes(service.serviceId) && service.count > 0) ||
                (upgradeServicesIds.includes(service.serviceId) && service.confirmedCount > 0));
        });
    });
});
export var getIsUpgradeClassSelectedForAllSegments = createSelector(getUpgradeClassServices, getTravellers, getSegments, function (upgradeServices, travellers, segments) {
    var e_1, _a, e_2, _b, e_3, _c;
    var _d, _e, _f, _g;
    var upgradeServicesIds = upgradeServices.map(function (_a) {
        var id = _a.id;
        return id;
    });
    var upgradeSelectedOnSegments = {};
    try {
        for (var travellers_1 = __values(travellers), travellers_1_1 = travellers_1.next(); !travellers_1_1.done; travellers_1_1 = travellers_1.next()) {
            var traveller = travellers_1_1.value;
            var upgradeServices_2 = (_g = (_f = (_e = (_d = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _d === void 0 ? void 0 : _d.gdsServices) === null || _e === void 0 ? void 0 : _e.services) === null || _f === void 0 ? void 0 : _f.filter(function (service) {
                return upgradeServicesIds.includes(service.serviceId);
            })) !== null && _g !== void 0 ? _g : [];
            try {
                for (var upgradeServices_1 = (e_2 = void 0, __values(upgradeServices_2)), upgradeServices_1_1 = upgradeServices_1.next(); !upgradeServices_1_1.done; upgradeServices_1_1 = upgradeServices_1.next()) {
                    var upgradeService = upgradeServices_1_1.value;
                    try {
                        for (var _h = (e_3 = void 0, __values(upgradeService.segmentIds)), _j = _h.next(); !_j.done; _j = _h.next()) {
                            var segmentId = _j.value;
                            if (!upgradeSelectedOnSegments[segmentId] &&
                                (upgradeService.count > 0 || upgradeService.confirmedCount > 0)) {
                                upgradeSelectedOnSegments[segmentId] = true;
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (upgradeServices_1_1 && !upgradeServices_1_1.done && (_b = upgradeServices_1.return)) _b.call(upgradeServices_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (travellers_1_1 && !travellers_1_1.done && (_a = travellers_1.return)) _a.call(travellers_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return (Object.keys(upgradeSelectedOnSegments).length ===
        segments.filter(function (segment) { return segment.subStatus === null; }).length);
});
export var getCanAddUpgradeFlightClass = createSelector(getUpgradeClassServices, function (upgradeServices) { return !!(upgradeServices === null || upgradeServices === void 0 ? void 0 : upgradeServices.length); });
export var getBusinessLounge = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.subType === GdsServiceVipSubType.BusinessLounge; });
});
export var getBusinessLoungeByTypes = createSelector(getServices, function (services) {
    var businessLoungeByTypeMap = new Map();
    services.forEach(function (service) {
        var _a;
        var vipService = service;
        if (vipService.subType === GdsServiceVipSubType.BusinessLounge ||
            vipService.subType === GdsServiceVipSubType.BusinessLoungeInternational) {
            businessLoungeByTypeMap.set(vipService.subType, __spreadArray(__spreadArray([], __read(((_a = businessLoungeByTypeMap.get(vipService.subType)) !== null && _a !== void 0 ? _a : [])), false), [
                vipService
            ], false));
        }
    });
    return __spreadArray([], __read(businessLoungeByTypeMap.values()), false);
});
export var getBaggageDelivery = createSelector([getOrder, getServices], function (order, services) {
    return services.reduce(function (services, service) {
        var newServices = __spreadArray([], __read(services), false);
        if (service.subType ===
            GdsServiceVipSubType.BaggageDelivery) {
            var serviceSegments = __spreadArray([], __read(getRelatedSegments(service.allowedSegments)), false);
            // WEBSKY-2899: сегмент должен быть без пересадки
            serviceSegments
                .filter(function (segmentId) {
                var _a, _b;
                return ((_b = (_a = order.flight.segmentGroups.find(function (group) {
                    return group.segments.some(function (segment) { return segment.id === segmentId; });
                })) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.length) === 1;
            })
                .forEach(function (segmentId) {
                return newServices.push(__assign(__assign({}, service), { allowedSegments: [[segmentId]] }));
            });
        }
        return newServices;
    }, []);
});
export var getCapsuleHotel = createSelector(getServices, function (services) {
    return services.filter(function (service) { return service.subType === GdsServiceVipSubType.CapsuleHotel; });
});
export var getPriorityBoarding = createSelector(getServices, function (services) {
    return services.filter(function (service) {
        return service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
            service.subType ===
                GdsServiceVipSubType.PriorityBoarding;
    });
});
export var getAdditionalBaggage = createSelector(getBaggage, function (items) {
    return items.filter(function (item) { return item.type === BaggageType.CheckedBaggage; });
});
var getFakeBaggage = createSelector(getBaggageServices, function (services) {
    return services.filter(function (service) { return service.isFake; }).map(convertBaggageObject);
});
export var getIncludedBaggage = createSelector(_getIncludedBaggage, getFakeBaggage, function (included, fake) {
    return included.concat(fake);
});
export var getUpgradeBaggage = createSelector(getBaggage, getTravellers, function (baggageItems, travellers) {
    var travellersByIdMap = new Map(travellers.map(function (traveller) {
        var _a, _b;
        if (!((_b = (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.length)) {
            return [traveller.id, null];
        }
        var travellerGdsServicesById = new Map(traveller.services.gdsServices.services.map(function (service) { return [
            service.serviceId,
            service.count > service.confirmedCount
        ]; }));
        return [traveller.id, travellerGdsServicesById];
    }));
    var isCanBeAddedOrRemoved = function (item) {
        var canBeRemoved = item.allowedPassengers.some(function (passengerId) { var _a; return (_a = travellersByIdMap.get(passengerId)) === null || _a === void 0 ? void 0 : _a.get(item.id); });
        return item.canBeAdded || canBeRemoved;
    };
    return baggageItems.filter(function (item) {
        return ((item.type === BaggageType.HandLuggageExcess || item.type === BaggageType.CarryOn) &&
            isCanBeAddedOrRemoved(item));
    });
});
export var getSpecialEquipment = createSelector(getBaggage, function (items) {
    return items.filter(function (item) {
        return [
            BaggageType.SportingEquipment,
            BaggageType.PetInCabin,
            BaggageType.PetInHold,
            BaggageType.MusicalInstrument
        ].includes(item.type);
    });
});
export var getOverweightBaggage = createSelector(getBaggage, function (items) {
    return items.filter(function (item) { return item.type === BaggageType.BaggageExcess; });
});
export var getRecommendedMeals = createSelector(getMeals, getTravellers, function (meals, travellers) {
    var result = [];
    travellers.forEach(function (traveller) {
        traveller.preselectedServices.gdsServices.services.forEach(function (service) {
            var meal = meals.find(function (meal) { return meal.id === service.serviceId; });
            if (meal) {
                result.push({
                    meal: meal,
                    travellerId: traveller.id,
                    segmentIds: service.segmentIds,
                    count: service.count
                });
            }
        });
    });
    return result;
});
export var getRecommendedBaggage = createSelector(getBaggage, getTravellers, function (baggage, travellers) {
    var recommendedBaggage = [];
    travellers.forEach(function (traveller) {
        var _a, _b, _c;
        (_c = (_b = (_a = traveller.preselectedServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.forEach(function (service) {
            var baggageService = baggage.find(function (baggage) { return baggage.id === service.serviceId; });
            if (baggageService) {
                recommendedBaggage.push({
                    baggage: baggageService,
                    travellerId: traveller.id,
                    count: service.count,
                    segmentIds: service.segmentIds,
                    confirmedCount: service.confirmedCount
                });
            }
        });
    });
    return recommendedBaggage;
});
export var getHasAnySelectedSeats = createSelector(getOrder, getPassengerStateCompatible, function (order, passengers) {
    var segmentsCount = order.flight.segments.length;
    return passengers
        .filter(function (passenger) { return passenger.type !== AviaPassengerType.INF; })
        .some(function (_a) {
        var _b = _a.seats, seats = _b === void 0 ? [] : _b;
        var segmentsBySeats = new Set(seats.map(function (seat) { return seat.segmentId; }));
        return seats.length > 0 && segmentsBySeats.size === segmentsCount;
    });
});
export var getHasAnySelectedMeals = createSelector(getPassengerStateCompatible, getRecommendedMeals, function (passengers, recommendedMeals) {
    return passengers.some(function (_a) {
        var services = _a.services;
        return services === null || services === void 0 ? void 0 : services.some(function (service) { return recommendedMeals.some(function (_a) {
            var meal = _a.meal;
            return meal.id === service.serviceId;
        }); });
    });
});
export var getSkipRecommendations = createSelector(getIsRedirectFromMetasearch, getIsOddOrderId, function (isFromMeta, orderIdIsOdd) { return isFromMeta && orderIdIsOdd; });
export var getIsTransferSelected = createSelector(getOrder, function (order) { var _a, _b, _c, _d; return ((_d = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) === null || _d === void 0 ? void 0 : _d.length) > 0; });
export var getIsInsuranceSelected = createSelector(getOrder, function (order) { var _a, _b, _c; return ((_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) === null || _c === void 0 ? void 0 : _c.length) > 0; });
export var getShowSeatRecommendations = createSelector(getSkipRecommendations, getOrderId, getTravellers, getHasAnySelectedSeats, function (skipRecommendations, orderId, travellers, hasAnySelectedSeats) {
    return !skipRecommendations &&
        // Check if we have something to recommend.
        travellers.some(function (traveller) { return traveller.preselectedServices.seats.length; });
});
export var getShowMealRecommendations = createSelector(getSkipRecommendations, getOrderId, getRecommendedMeals, getHasAnySelectedMeals, function (skipRecommendations, orderId, recommendedMeals, hasAnySelectedMeals) {
    return !skipRecommendations &&
        // Check if user hasn't selected any meals.
        !hasAnySelectedMeals &&
        // Check if we have something to recommend.
        !!recommendedMeals.length &&
        // Check if user hasn't declined our recommendations yet.
        !get("".concat(MEAL_RECOMMENDATION_SHOWN, "_").concat(orderId));
});
export var getIsNeedToConfirmConsents = createSelector(useConfig, function (config) {
    return !['A9', 'RM', 'DV', 'JI', '5G', '2S'].includes(config.global.companyInfo.iataCode);
});
export var getTravellersValueNames = createSelector(getTravellers, function (travellers) {
    var map = new Map();
    if ((travellers === null || travellers === void 0 ? void 0 : travellers.length) !== 0) {
        travellers[0].values.forEach(function (v) {
            map.set(v.type, v.name);
        });
    }
    return map;
});
export var getHasRequestedOrderServices = createSelector(getOrder, getIsBookedOrConfirmed, getTravellers, function (order, isBookedOrConfirmed, travellers) {
    return isBookedOrConfirmed && getHasRequestedServices(travellers, order.ssrData);
});
