import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useMemo } from 'react';
import { LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { cityFrom } from 'lvovich';
import cn from 'classnames';
import { useLocationsQuery, LocationAdditionalGroupType, LocationGroupType } from '@websky/graphql';
import { useTheme } from '../../../theme';
import Location from '../Location/Location';
var Locations = function (_a) {
    var className = _a.className, departure = _a.departure, onSelect = _a.onSelect;
    var theme = useTheme('CoreSchedule').Locations;
    var t = useTranslation('Schedule').t;
    var _b = useLocationsQuery({
        variables: {
            parameters: {
                additionalCities: [],
                additionalGroups: [
                    LocationAdditionalGroupType.Nearest,
                    LocationAdditionalGroupType.AdditionalCities,
                    LocationAdditionalGroupType.Popular
                ],
                departureFrom: (departure === null || departure === void 0 ? void 0 : departure.iata) || null,
                query: '',
                userPosition: null,
                directOnly: true
            }
        }
    }), data = _b.data, loading = _b.loading;
    var locations = useMemo(function () {
        var cities = [];
        if (data === null || data === void 0 ? void 0 : data.LocationSuggestions) {
            data.LocationSuggestions.forEach(function (location) {
                var _a;
                if (location.type !== LocationGroupType.Popular && ((_a = location.country) === null || _a === void 0 ? void 0 : _a.name)) {
                    cities = __spreadArray(__spreadArray([], __read(cities), false), __read(location.cities.map(function (city) { return (__assign(__assign({}, city), { country: location.country.name })); })), false);
                }
            });
        }
        return cities.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            else if (a.name < b.name) {
                return -1;
            }
            return 0;
        });
    }, [data === null || data === void 0 ? void 0 : data.LocationSuggestions, loading]);
    var onClickHandler = function (iata) { return function () { return onSelect(iata); }; };
    return (React.createElement(React.Fragment, null,
        loading && (React.createElement(LinearProgress, { classes: {
                root: theme.progress,
                barColorPrimary: theme.barColorPrimary,
                colorPrimary: theme.colorPrimary
            } })),
        locations.length > 0 && (React.createElement("div", { className: cn(theme.container, className) },
            React.createElement("div", { className: theme.title },
                t("Where are you flying ".concat(departure ? 'to' : 'from')),
                (departure === null || departure === void 0 ? void 0 : departure.name) ? " ".concat(cityFrom(departure.name)) : '',
                "?"),
            React.createElement("div", { className: theme.locations }, locations.map(function (location) { return (React.createElement(Location, { key: "".concat(location.country, "-").concat(location.name), className: theme.location, country: location.country, city: location.name, onClick: onClickHandler(location), disabled: loading })); }))))));
};
export default Locations;
