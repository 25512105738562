import { __assign } from "tslib";
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import MediaQuery from '../../../../../MediaQuery/MediaQuery';
import cn from 'classnames';
import { SeatComfort } from '@websky/graphql';
import { findPassengerWithThisSeat } from '../../Seat/utils';
import { CombinedDirection } from '../../../../types';
import { useTheme } from '../../../../../theme';
import Seat from '../../Seat/Seat';
import { getAllowedPassengerSeatServices, getSeatAvailability } from './utils';
import { useTranslation } from 'react-i18next';
import { cabinCodeComfortMap, getActualService, getMinPriceOfServiceClass } from '../../utils';
import { OverrideComponent } from '../../../../../renderProps';
import { useConfig } from '../../../../../context';
import { useDoubleSeat } from '../../../../hooks';
import TechnicalFacilities from './TechnicalFacilities/TechnicalFacilities';
import SeatPrice from '../../../SeatPrice/SeatPrice';
import { getSeatPrice, isZeroPrice } from '../../../../utils';
var Parts = React.memo(function (_a) {
    var _b, _c;
    var _d, _e;
    var part = _a.part, nextPartService = _a.nextPartService, prevPartService = _a.prevPartService, partIndex = _a.partIndex, passengerId = _a.passengerId, serviceId = _a.serviceId, segmentId = _a.segmentId, isSeatMapWide = _a.isSeatMapWide, passengers = _a.passengers, allSeatServices = _a.allSeatServices, partService = _a.partService, isCheckinMode = _a.isCheckinMode, onSelect = _a.onSelect, newClass = _a.newClass, onClear = _a.onClear, isStatic = _a.isStatic, rows = _a.rows, rowIndex = _a.rowIndex, activeDoubleSeats = _a.activeDoubleSeats, rowsWithDifferentClasses = _a.rowsWithDifferentClasses;
    var theme = useTheme('SeatMap').SeatMapStyles;
    var t = useTranslation('SeatMap').t;
    var nextPartServiceId = nextPartService === null || nextPartService === void 0 ? void 0 : nextPartService.comfort;
    var prevPartServiceId = prevPartService === null || prevPartService === void 0 ? void 0 : prevPartService.comfort;
    var passengerServices = (_d = allSeatServices === null || allSeatServices === void 0 ? void 0 : allSeatServices[segmentId]) === null || _d === void 0 ? void 0 : _d[passengerId];
    var actualService = React.useMemo(function () { return getActualService(passengerServices, part); }, [passengerServices, part]);
    var minServicePrice = newClass && actualService
        ? getMinPriceOfServiceClass(rows, rowIndex, actualService, rowsWithDifferentClasses)
        : null;
    var isAllSeatsFree = React.useMemo(function () { return part.seats.every(function (seat) { return isZeroPrice(getSeatPrice(seat)); }); }, [
        part.seats
    ]);
    var _f = useConfig().global, iataCode = _f.companyInfo.iataCode, splitByCabin = _f.seatMap.splitByCabin;
    var showSeatPriceNotServicePrice = ['DV', 'RM'].includes(iataCode);
    var _g = useDoubleSeat(activeDoubleSeats, segmentId, part.seats, passengers, onSelect), combineWith = _g.combineWith, selectedDoubleSeatsBySegments = _g.selectedDoubleSeatsBySegments, combinedSeat = _g.combinedSeat, onSelectHandler = _g.onSelectHandler, onMouseEnter = _g.onMouseEnter, onMouseLeave = _g.onMouseLeave;
    var renderServiceHeader = useCallback(function (comfort) {
        var _a;
        return (React.createElement("div", { className: cn(theme.row__serviceHeader, (_a = {},
                _a[theme.row__serviceHeader_comfort] = comfort === SeatComfort.Comfort,
                _a[theme.row__serviceHeader_preferred] = comfort === SeatComfort.Preferred,
                _a[theme.row__serviceHeader_free] = isAllSeatsFree,
                _a)) },
            React.createElement("div", { className: theme.row__serviceHeader__wrapper },
                React.createElement("span", { className: theme.row__serviceHeader__serviceName }, t("SeatMap_".concat(comfort))),
                minServicePrice && (React.createElement(SeatPrice, { className: theme.row__serviceHeader__price, priceFromClassName: theme.row_serviceHeader_from, money: minServicePrice, priceFrom: true })))));
    }, [minServicePrice]);
    var delimiter = useMemo(function () {
        var _a;
        if (!newClass || ((_a = part.service) === null || _a === void 0 ? void 0 : _a.isAisle)) {
            return null;
        }
        if (splitByCabin) {
            var rowService = cabinCodeComfortMap.get(rows[rowIndex].cabinCode);
            return renderServiceHeader(rowService);
        }
        else {
            if (actualService) {
                return renderServiceHeader(actualService);
            }
        }
        return null;
    }, [splitByCabin, part, newClass, actualService, minServicePrice]);
    return (React.createElement("div", { className: theme.row__PartWrpWithTechFacilities },
        React.createElement("div", { className: cn(theme.row__rowPartWrp, (_b = {},
                _b[theme.row__seatWrapper_newClass] = newClass,
                _b[theme.row__rowPartWrp_aisle] = (_e = part.service) === null || _e === void 0 ? void 0 : _e.isAisle,
                _b[theme.row__rowPartWrp_static] = isStatic,
                _b)), key: partIndex },
            React.createElement(MediaQuery, { minWidth: 'tablet' },
                React.createElement("div", { className: cn(theme.row__seatWrapper, (_c = {},
                        _c[theme.row__seatWrapper_boundaryRight] = partService !== nextPartServiceId,
                        _c[theme.row__seatWrapper_boundaryLeft] = partService !== prevPartServiceId,
                        _c[theme.row__seatWrapper_service_business] = partService === SeatComfort.Comfort,
                        _c[theme.row__seatWrapper_service_preferred] = partService === SeatComfort.Preferred,
                        _c[theme.row__seatWrapper_service_standard] = partService === SeatComfort.Standart,
                        _c[theme.row__seatWrapper_service_business_wide] = partService === SeatComfort.Comfort && isSeatMapWide,
                        _c[theme.row__seatWrapper_service_preferred_wide] = partService === SeatComfort.Preferred && isSeatMapWide,
                        _c[theme.row__seatWrapper_service_standard_wide] = partService === SeatComfort.Standart && isSeatMapWide,
                        _c)), "data-service-id": serviceId })),
            delimiter,
            part.seats.map(function (seat, index) {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                var currentPassenger = passengers.find(function (passenger) { return passenger.id === passengerId; });
                var selectedSeat = (_a = currentPassenger.seats) === null || _a === void 0 ? void 0 : _a.find(function (seat) { return seat.segmentId === segmentId; });
                var prevSeat = (_b = part.seats) === null || _b === void 0 ? void 0 : _b[index - 1];
                var nextSeat = (_c = part.seats) === null || _c === void 0 ? void 0 : _c[index + 1];
                var actualSeat = seat;
                var occupiedBy = findPassengerWithThisSeat(actualSeat, passengers, segmentId);
                if (((actualSeat === null || actualSeat === void 0 ? void 0 : actualSeat.seatService) || !!((_d = actualSeat === null || actualSeat === void 0 ? void 0 : actualSeat.seatServices) === null || _d === void 0 ? void 0 : _d.length)) &&
                    !showSeatPriceNotServicePrice) {
                    var allowedPassengerSeatService = getAllowedPassengerSeatServices(passengerServices, seat);
                    if (!!(allowedPassengerSeatService === null || allowedPassengerSeatService === void 0 ? void 0 : allowedPassengerSeatService.length)) {
                        actualSeat = __assign({}, seat);
                        actualSeat.services = __assign({}, allowedPassengerSeatService);
                        actualSeat.service = __assign({}, allowedPassengerSeatService[0]);
                        actualSeat.seatService = __assign({}, allowedPassengerSeatService[0]);
                        actualSeat.price = {
                            amount: allowedPassengerSeatService.reduce(function (prev, currentService) { return prev + (currentService.price.amount || 0); }, 0),
                            currency: allowedPassengerSeatService[0].price.currency
                        };
                    }
                }
                var hasConfirmedSeat = (_e = currentPassenger.seats) === null || _e === void 0 ? void 0 : _e.some(function (seat) { return (seat === null || seat === void 0 ? void 0 : seat.isConfirmed) && seat.segmentId === segmentId; });
                var isAvailable = getSeatAvailability({
                    seat: actualSeat,
                    passenger: currentPassenger,
                    segmentId: segmentId
                }).isAvailable;
                if (isCheckinMode) {
                    if (hasConfirmedSeat) {
                        isAvailable = (selectedSeat === null || selectedSeat === void 0 ? void 0 : selectedSeat.row) === actualSeat.number;
                    }
                    if (actualSeat === null || actualSeat === void 0 ? void 0 : actualSeat.seatService) {
                        isAvailable = isAvailable && actualSeat.seatService.canBeAdded;
                    }
                }
                var isConfirmed = hasConfirmedSeat && (selectedSeat === null || selectedSeat === void 0 ? void 0 : selectedSeat.number) === actualSeat.number;
                var hasCombinedSeats = seat.combinedWith && Object.keys(seat.combinedWith).length > 0;
                var isSelectedDoubleSeat = (_f = selectedDoubleSeatsBySegments.get(segmentId)) === null || _f === void 0 ? void 0 : _f.has(seat.number);
                var isOccupiedDoubleSeat = occupiedBy && isSelectedDoubleSeat;
                if (activeDoubleSeats) {
                    isAvailable = isAvailable && hasCombinedSeats && !hasConfirmedSeat;
                    if (!occupiedBy &&
                        ((!prevSeat &&
                            nextSeat &&
                            findPassengerWithThisSeat(nextSeat, passengers, segmentId)) ||
                            (!nextSeat &&
                                prevSeat &&
                                findPassengerWithThisSeat(prevSeat, passengers, segmentId)))) {
                        isAvailable = false;
                    }
                    else if (occupiedBy && !isSelectedDoubleSeat) {
                        isAvailable = false;
                    }
                }
                var isCombinedWith = activeDoubleSeats ? combineWith === null || combineWith === void 0 ? void 0 : combineWith[seat.number] : null;
                if (isOccupiedDoubleSeat &&
                    prevSeat &&
                    ((_g = selectedDoubleSeatsBySegments.get(segmentId)) === null || _g === void 0 ? void 0 : _g.has(prevSeat.number)) &&
                    findPassengerWithThisSeat(prevSeat, passengers, segmentId)) {
                    isCombinedWith = CombinedDirection.Left;
                }
                else if (isOccupiedDoubleSeat &&
                    nextSeat &&
                    ((_h = selectedDoubleSeatsBySegments.get(segmentId)) === null || _h === void 0 ? void 0 : _h.has(nextSeat.number)) &&
                    findPassengerWithThisSeat(nextSeat, passengers, segmentId)) {
                    isCombinedWith = CombinedDirection.Right;
                }
                var onMouseEnterHandler = function () { return onMouseEnter(seat, segmentId); };
                return (React.createElement(OverrideComponent, { componentProps: {
                        isSmall: isSeatMapWide,
                        seat: actualSeat,
                        isAvailable: isAvailable && !hasConfirmedSeat,
                        occupiedBy: occupiedBy,
                        isConfirmed: hasConfirmedSeat,
                        segmentId: segmentId,
                        passengerId: passengerId,
                        onClear: onClear,
                        onSelect: isConfirmed ? function () { return null; } : onSelectHandler,
                        onMouseEnter: onMouseEnterHandler,
                        onMouseLeave: onMouseLeave,
                        isCombinedWith: isCombinedWith,
                        combinedSeat: combinedSeat,
                        passenger: currentPassenger
                    }, component: { SeatMapSeat: Seat }, key: actualSeat.number }));
            })),
        React.createElement(TechnicalFacilities, { part: part, isSmall: isSeatMapWide })));
});
export default Parts;
