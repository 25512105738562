import { __assign, __read, __spreadArray, __values } from "tslib";
import { PriceBreakdownType } from './store/priceBreakdown/action';
import { AviaPassengerType, BaggageType, OrderAdditionalServiceGdsServiceServiceType, SeatType, TravellerFieldEnum } from '@websky/graphql';
import { Currency } from '../enums';
import { getSelectedSeatsBySegment } from '../SeatMap/utils';
import { Measurement } from '../BaggageService/types';
import { getRelatedSegments } from '../Modules/Checkin/utils';
import { API_DATE_FORMAT, format, getMinPrice, getUserValue, setsAreEqual } from '../utils';
import { addServiceAction_NS, removeServiceAction_NS, deleteAllTypesOnSegmentAction_NS, setMultipleSelectedServicesAction_NS, saveServicesAction } from './store/selectedServices/actions';
import { addDays } from 'date-fns';
import { clearPhoneNumber } from '../PhoneInput/utils';
export var parsePriceBreakdown = function (priceBreakdown) {
    var price = {
        totalPrice: {
            amount: 0,
            currency: Currency.USD
        },
        servicePrice: {
            amount: 0,
            currency: Currency.USD
        },
        upsalePrice: {
            amount: 0,
            currency: Currency.USD
        }
    };
    price.totalPrice = priceBreakdown.price;
    priceBreakdown.parts.forEach(function (part) {
        if (part.type === PriceBreakdownType.Service) {
            price.servicePrice.amount += part.price.amount;
            price.servicePrice.currency += part.price.currency;
            part.parts.forEach(function (part) {
                if (part.type === PriceBreakdownType.Upsale) {
                    price.upsalePrice.amount += part.price.amount;
                    price.upsalePrice.currency += part.price.currency;
                }
            });
        }
    });
    return price;
};
export var getGdsServiceMinPrice = function (order, gdsService, baggageTypes) {
    var _a, _b, _c;
    var prices = [];
    if ((_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.length) {
        order.additionalServices.gdsServices.services.forEach(function (service) {
            var _a;
            if (((_a = service.price) === null || _a === void 0 ? void 0 : _a.amount) > 0 && gdsService !== OrderAdditionalServiceGdsServiceServiceType.Seat) {
                var includeBaggageType = !(baggageTypes === null || baggageTypes === void 0 ? void 0 : baggageTypes.length) ||
                    ((baggageTypes === null || baggageTypes === void 0 ? void 0 : baggageTypes.length) && baggageTypes.includes(service.baggageType));
                if (service.type === gdsService && includeBaggageType) {
                    prices = __spreadArray(__spreadArray([], __read(prices), false), [service.price], false);
                }
            }
        });
    }
    return getMinPrice(prices, function (price) { return price; });
};
export var removeServicesStepsByTypes = function (steps, types) {
    return steps.filter(function (step) {
        return types.find(function (type) { return step.type !== type; });
    });
};
var getPassengerDisabilities = function (passengerDisabilities) {
    var disabilities = [];
    if (!(passengerDisabilities === null || passengerDisabilities === void 0 ? void 0 : passengerDisabilities.enabled)) {
        return null;
    }
    Object.entries(passengerDisabilities.values).forEach(function (_a) {
        var _b = __read(_a, 2), key = _b[0], isChecked = _b[1];
        if (isChecked) {
            disabilities.push(key);
        }
    }, null);
    return disabilities.length ? disabilities : null;
};
export var prepareBookingRequest = function (orderId, formValues, orderSubsidyState) {
    var values = {
        params: {
            id: orderId,
            customer: {
                values: [
                    {
                        name: 'email',
                        value: formValues['customer']['email']
                    },
                    {
                        name: 'phone',
                        value: clearPhoneNumber(formValues['customer']['phone'])
                    }
                ],
                subscribed: formValues['subscribed']
            },
            travellers: formValues['passengers'].map(function (passenger, index) {
                var _a, _b;
                var values = [];
                for (var fieldName in passenger) {
                    if (passenger.hasOwnProperty(fieldName)) {
                        if (!['type', 'disabilities', 'files'].includes(fieldName)) {
                            values.push({
                                name: fieldName,
                                value: fieldName === 'phone'
                                    ? clearPhoneNumber(passenger[fieldName])
                                    : passenger[fieldName]
                            });
                        }
                    }
                }
                return {
                    id: index.toString(),
                    values: values,
                    subsidies: (orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.isSubsidiesAvailable)
                        ? {
                            selectedSubsidies: (_b = (_a = orderSubsidyState.travellers.find(function (traveller) { return traveller.traveller.id === index.toString(); })) === null || _a === void 0 ? void 0 : _a.subsidies) !== null && _b !== void 0 ? _b : []
                        }
                        : null,
                    disabilities: getPassengerDisabilities(passenger['disabilities'])
                };
            })
        }
    };
    if (formValues['customer']['accompanyingPersonName'] && formValues['customer']['accompanyingPersonTicketNumber']) {
        values.params.customer.values.push({
            name: 'accompanyingPersonName',
            value: formValues['customer']['accompanyingPersonName']
        }, {
            name: 'accompanyingPersonTicketNumber',
            value: formValues['customer']['accompanyingPersonTicketNumber']
        });
    }
    return values;
};
export var checkInternationalFlight = function (flight) {
    return flight.segments.some(function (segment) { return segment.segment.arrival.airport.country.id !== segment.segment.departure.airport.country.id; });
};
export var getServicesForBaggageIncrease = function (selectedService, baggageServices, passengersState) {
    var createNewServices = true;
    var baggage = selectedService.baggage, segmentId = selectedService.segmentId, passengerId = selectedService.passengerId, forAllSegments = selectedService.forAllSegments;
    var services = [];
    var baggageMap = new Map(baggageServices.map(function (baggage) { return [baggage.id, true]; }));
    var relatedSegments = getRelatedSegments(baggage.segmentIds, forAllSegments ? null : segmentId);
    passengersState[parseInt(passengerId)].services.forEach(function (selectedService) {
        if (baggageMap.has(selectedService.serviceId)) {
            if (selectedService.serviceId === baggage.id &&
                (forAllSegments || setsAreEqual(relatedSegments, new Set(selectedService.segmentIds)))) {
                // Do not create new services, cuz we already have one.
                createNewServices = false;
                services.push(__assign(__assign({}, selectedService), { count: selectedService.count + 1 }));
            }
            else {
                services.push(selectedService);
            }
        }
    });
    if (createNewServices) {
        services.push({
            serviceId: baggage.id,
            count: 1,
            segmentIds: __spreadArray([], __read(relatedSegments), false)
        });
    }
    return [
        {
            passengerId: passengerId,
            services: services
        }
    ];
};
export var getServicesForBaggageDecrease = function (selectedService, baggageServices, passengersState) {
    var baggage = selectedService.baggage, segmentId = selectedService.segmentId, passengerId = selectedService.passengerId, forAllSegments = selectedService.forAllSegments;
    var services = [];
    var relatedSegments = getRelatedSegments(baggage.segmentIds, forAllSegments ? null : segmentId);
    passengersState[parseInt(passengerId)].services.forEach(function (selectedService) {
        if (selectedService.serviceId === baggage.id &&
            (forAllSegments || setsAreEqual(relatedSegments, new Set(selectedService.segmentIds)))) {
            services.push(__assign(__assign({}, selectedService), { count: selectedService.count > 0 ? selectedService.count - 1 : 0 }));
        }
        else if (selectedService.serviceId !== baggage.id ||
            (selectedService.segmentIds[0] !== segmentId && !forAllSegments)) {
            services.push(selectedService);
        }
    });
    return [
        {
            passengerId: passengerId,
            services: services
        }
    ];
};
export var getServicesForBaggageReplace = function (oldBaggage, newBaggage, segmentId, passengerId, forAllSegments, baggageServices, passengersState) {
    var services = [];
    var relatedSegments = getRelatedSegments(newBaggage.segmentIds, forAllSegments ? null : segmentId);
    // Remove old baggage.
    passengersState[parseInt(passengerId)].services.forEach(function (service) {
        if (service.serviceId === oldBaggage.id &&
            (forAllSegments || setsAreEqual(relatedSegments, new Set(service.segmentIds)))) {
            services.push(__assign(__assign({}, service), { count: 0 }));
        }
        else {
            services.push(service);
        }
    });
    // Add new baggage for given segments.
    services.push({
        serviceId: newBaggage.id,
        count: 1,
        segmentIds: __spreadArray([], __read(relatedSegments), false)
    });
    return [
        {
            passengerId: passengerId,
            services: services
        }
    ];
};
export var getServicesForBaggageClear = function (passengerId, baggageServices, passengersState) {
    var newServices = [];
    var baggageServicesIds = baggageServices.map(function (service) { return service.id; });
    passengersState[parseInt(passengerId)].services.forEach(function (service) {
        if (baggageServicesIds.includes(service.serviceId)) {
            newServices.push(__assign(__assign({}, service), { count: 0 }));
        }
        else {
            newServices.push(__assign({}, service));
        }
    });
    return [
        {
            passengerId: passengerId,
            services: newServices
        }
    ];
};
export var getServicesForBaggageModeChange = function (passengerId, segments, baggageServices, passengersState) {
    var services = {};
    passengersState[parseInt(passengerId)].services.forEach(function (service) {
        if (!services[service.serviceId] || services[service.serviceId] < service.count) {
            services[service.serviceId] = service.count;
        }
    });
    var newServices = [];
    var _loop_1 = function (service) {
        if (services.hasOwnProperty(service)) {
            segments.forEach(function (_a) {
                var id = _a.id;
                newServices.push({
                    serviceId: service,
                    count: services[service],
                    segmentIds: [id]
                });
            });
        }
    };
    for (var service in services) {
        _loop_1(service);
    }
    return [{ passengerId: passengerId, services: newServices }];
};
export var getBaggageCallbacks = function (runServiceRequest, baggageServices, passengers, segments) {
    var baggageRequestAdapter = function (passengers) {
        return runServiceRequest(passengers.map(function (_a) {
            var passengerId = _a.passengerId, services = _a.services;
            return ({
                passengerId: passengerId,
                setServices: services
            });
        }));
    };
    return {
        onIncrease: function (baggage, segmentId, passengerId, forAllSegments) {
            return baggageRequestAdapter(getServicesForBaggageIncrease({ baggage: baggage, segmentId: segmentId, passengerId: passengerId, forAllSegments: forAllSegments }, baggageServices, passengers));
        },
        onDecrease: function (baggage, segmentId, passengerId, forAllSegments) {
            return baggageRequestAdapter(getServicesForBaggageDecrease({ baggage: baggage, segmentId: segmentId, passengerId: passengerId, forAllSegments: forAllSegments }, baggageServices, passengers));
        },
        onReplace: function (oldBaggage, newBaggage, segmentId, passengerId, forAllSegments) {
            return baggageRequestAdapter(getServicesForBaggageReplace(oldBaggage, newBaggage, segmentId, passengerId, forAllSegments, baggageServices, passengers));
        },
        onModeChange: function (passengerId) {
            return baggageRequestAdapter(getServicesForBaggageModeChange(passengerId, segments, baggageServices, passengers));
        }
    };
};
export var getBaggageReduxCallbacks = function (passengers, baggage, segments, addService, removeService, setService) {
    return {
        onIncrease: function (baggage, segmentId, passengerId, forAllSegments) {
            if (!forAllSegments) {
                addService({
                    segmentId: segmentId,
                    passengerId: passengerId,
                    serviceId: baggage.id,
                    service: {
                        id: baggage.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    },
                    allowedSegments: baggage.segmentIds
                });
            }
            else {
                var relatedSegments = getRelatedSegments(baggage.segmentIds, forAllSegments ? null : segmentId);
                relatedSegments.forEach(function (segmentId) {
                    addService({
                        segmentId: segmentId,
                        passengerId: passengerId,
                        serviceId: baggage.id,
                        service: {
                            id: baggage.id,
                            type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                        },
                        allowedSegments: baggage.segmentIds
                    });
                });
            }
        },
        onDecrease: function (baggage, segmentId, passengerId, forAllSegments) {
            if (!forAllSegments) {
                removeService({
                    segmentId: segmentId,
                    passengerId: passengerId,
                    serviceId: baggage.id,
                    service: {
                        id: baggage.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    },
                    allowedSegments: baggage.segmentIds
                });
            }
            else {
                var relatedSegments = getRelatedSegments(baggage.segmentIds, forAllSegments ? null : segmentId);
                relatedSegments.forEach(function (segmentId) {
                    removeService({
                        segmentId: segmentId,
                        passengerId: passengerId,
                        serviceId: baggage.id,
                        service: {
                            id: baggage.id,
                            type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                        },
                        allowedSegments: baggage.segmentIds
                    });
                });
            }
        },
        onReplace: function (oldBaggage, newBaggage, segmentId, passengerId, forAllSegments) {
            var relatedSegments = getRelatedSegments(oldBaggage.segmentIds, forAllSegments ? null : segmentId), newRelatedSegments = getRelatedSegments(newBaggage.segmentIds, forAllSegments ? null : segmentId);
            if (forAllSegments) {
                relatedSegments.forEach(function (segmentId) {
                    removeService({
                        serviceId: oldBaggage.id,
                        passengerId: passengerId,
                        segmentId: segmentId,
                        service: {
                            type: OrderAdditionalServiceGdsServiceServiceType.Baggage,
                            price: oldBaggage.price,
                            id: oldBaggage.id
                        }
                    });
                });
            }
            else {
                removeService({
                    serviceId: oldBaggage.id,
                    passengerId: passengerId,
                    segmentId: segmentId
                });
            }
            newRelatedSegments.forEach(function (segmentId) {
                setService({
                    serviceId: newBaggage.id,
                    passengerId: passengerId,
                    segmentId: segmentId,
                    service: {
                        id: newBaggage.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    },
                    allowedSegments: newBaggage.segmentIds
                });
            });
        },
        onModeChange: function (passengerId) {
            var passenger = passengers.find(function (passenger) { return passenger.id === passengerId; });
            var baggageIds = baggage.map(function (baggage) { return baggage.id; });
            var services = {};
            passenger.services.forEach(function (service) {
                if (baggageIds.includes(service.serviceId) &&
                    (!services[service.serviceId] || services[service.serviceId] < service.count)) {
                    services[service.serviceId] = service.count;
                }
            });
            var _loop_2 = function (service) {
                if (services.hasOwnProperty(service)) {
                    var baggageService_1 = baggage.find(function (baggage) { return baggage.id === service; });
                    segments.forEach(function (segment) {
                        setService({
                            passengerId: passengerId,
                            service: {
                                id: service,
                                type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                            },
                            count: services[service],
                            serviceId: service,
                            segmentId: segment.id,
                            allowedSegments: baggageService_1.segmentIds
                        });
                    });
                }
            };
            for (var service in services) {
                _loop_2(service);
            }
        },
        onClear: function (passengerId, type) {
            var passenger = passengers.find(function (passenger) { return passenger.id === passengerId; });
            var baggageIds = baggage
                .filter(function (service) { return (type ? service.type === type : true); })
                .map(function (service) { return service.id; });
            passenger.services.forEach(function (selectedBaggage) {
                selectedBaggage.segmentIds.forEach(function (segmentId) {
                    if (baggageIds.includes(selectedBaggage.serviceId)) {
                        setService({
                            serviceId: selectedBaggage.serviceId,
                            count: 0,
                            passengerId: passengerId,
                            segmentId: segmentId,
                            service: {
                                id: selectedBaggage.serviceId,
                                type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                            },
                            allowedSegments: baggage.find(function (baggage) { return baggage.id === selectedBaggage.serviceId; })
                                .segmentIds
                        });
                    }
                });
            });
        }
    };
};
export var getMealReduxCallbacks = function (dispatch) {
    return {
        onMealAdd: function (meal, segmentId, passengerId, count) {
            dispatch(addServiceAction_NS({
                passengerId: passengerId,
                segmentId: segmentId,
                serviceId: meal.id,
                allowedSegments: meal.allowedSegments,
                count: count,
                service: {
                    id: meal.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Meal,
                    name: meal.name,
                    price: meal.price
                }
            }));
        },
        onMealRemove: function (meal, segmentId, passengerId, count) {
            dispatch(removeServiceAction_NS({
                passengerId: passengerId,
                segmentId: segmentId,
                serviceId: meal.id,
                count: count,
                allowedSegments: meal.allowedSegments,
                service: {
                    id: meal.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Meal,
                    name: meal.name,
                    price: meal.price
                }
            }));
        },
        onMealAddForPassengers: function (meal, segmentId, passengerIds, count) {
            var services = passengerIds.map(function (passengerId) { return ({
                passengerId: passengerId,
                segmentId: segmentId,
                serviceId: meal.id,
                count: count,
                allowedSegments: meal.allowedSegments,
                service: {
                    id: meal.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Meal,
                    name: meal.name,
                    price: meal.price
                }
            }); });
            dispatch(setMultipleSelectedServicesAction_NS(services));
        },
        onMealClear: function (meal, segmentId) {
            dispatch(deleteAllTypesOnSegmentAction_NS({
                segmentId: segmentId,
                serviceId: meal.id
            }));
        },
        onMealConfirm: function () {
            dispatch(saveServicesAction());
        }
    };
};
export var isSelectedSeatGuard = function (seat) {
    return seat.isConfirmed !== undefined;
};
export var convertSeatsToServicePayload = function (seats, passengerId, segmentId, isExtraSeats, count) {
    return seats.map(function (seat, index) {
        var _a;
        return (__assign({ segmentId: segmentId, passengerId: passengerId, serviceId: seat.number, service: {
                letter: seat.letter,
                row: seat.row,
                id: !isSelectedSeatGuard(seat) ? (_a = seat.service) === null || _a === void 0 ? void 0 : _a.id : null,
                deckId: null,
                type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                rfisc: seat.rfisc,
                price: seat.price,
                number: seat.number,
                segmentId: segmentId,
                seatType: index === 0 && isExtraSeats ? SeatType.extraSeat : null
            } }, (count !== undefined ? { count: count } : {})));
    });
};
export var getSeatReduxCallbacks = function (passengers, setMultipleServicesAction) {
    return {
        setSeatCallback: function (seats, passengerId, segmentId) {
            var _a;
            var isExtraSeats = (seats === null || seats === void 0 ? void 0 : seats.length) > 1;
            var seatNumbers = (seats === null || seats === void 0 ? void 0 : seats.map(function (seat) { return seat.number; })) || [];
            var services = (seats === null || seats === void 0 ? void 0 : seats.length)
                ? convertSeatsToServicePayload(seats, passengerId, segmentId, isExtraSeats)
                : [];
            var passengerSeats = ((_a = passengers.find(function (passenger) { return passenger.id === passengerId; })) === null || _a === void 0 ? void 0 : _a.seats) || [];
            var selectedSeatsBySegmentId = passengerSeats.filter(function (seat) { return seat.segmentId === segmentId && !seatNumbers.includes(seat.number); });
            var passengerOccupiedThisSeatBefore = passengers
                .filter(function (passenger) { return passenger.id !== passengerId; })
                .find(function (passenger) {
                var _a;
                return (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.some(function (selectedSeat) {
                    return selectedSeat.segmentId === segmentId && seatNumbers.includes(selectedSeat.number);
                });
            });
            if (selectedSeatsBySegmentId.length) {
                services = __spreadArray(__spreadArray([], __read(services), false), __read(convertSeatsToServicePayload(selectedSeatsBySegmentId, passengerId, segmentId, false, 0)), false);
            }
            if (passengerOccupiedThisSeatBefore) {
                services = __spreadArray(__spreadArray([], __read(services), false), __read(convertSeatsToServicePayload(passengerOccupiedThisSeatBefore.seats || [], passengerOccupiedThisSeatBefore.id, segmentId, false, 0)), false);
            }
            if (services.length) {
                setMultipleServicesAction(services);
            }
        },
        setClearSeatCallback: function (seats, passengerId, segmentId) {
            setMultipleServicesAction(convertSeatsToServicePayload(seats, passengerId, segmentId, false, 0));
        },
        onSegmentClear: function (segmentId) {
            var services = [];
            passengers.forEach(function (passenger) {
                var _a;
                var selectedSeats = ((_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.filter(function (seat) { return parseInt(seat.segmentId) === segmentId; })) || [];
                services = __spreadArray(__spreadArray([], __read(services), false), __read(convertSeatsToServicePayload(selectedSeats, passenger.id, segmentId.toString(), false, 0)), false);
            });
            if (services.length) {
                setMultipleServicesAction(services);
            }
        },
        onPassengerClear: function (passengerId) {
            var _a;
            var services = [];
            var passengerSeats = ((_a = passengers.find(function (passenger) { return passenger.id === passengerId; })) === null || _a === void 0 ? void 0 : _a.seats) || [];
            passengerSeats.forEach(function (seat) {
                services = __spreadArray(__spreadArray([], __read(services), false), __read(convertSeatsToServicePayload([seat], passengerId, seat.segmentId, false, 0)), false);
            });
            if (services.length) {
                setMultipleServicesAction(services);
            }
        }
    };
};
export var onClearPassengerMeals = function (meal, segmentId, passengers, selectedMeals) {
    // Remove all meal products of given type for each passenger on given segment.
    var passengersServices = [];
    passengers.forEach(function (passenger) {
        var products = [];
        selectedMeals.forEach(function (selectedMeal) {
            if (selectedMeal.passengerId === passenger.id) {
                products.push({
                    serviceId: selectedMeal.mealId,
                    segmentIds: [selectedMeal.segmentId],
                    count: selectedMeal.mealId === meal.id && selectedMeal.segmentId === segmentId ? 0 : selectedMeal.count
                });
            }
        });
        if (products.length) {
            passengersServices.push({
                passengerId: passenger.id,
                setServices: products
            });
        }
    });
    return passengersServices;
};
export var onMealAdd = function (meal, segmentId, passengerId, selectedMeals) {
    var hasFound = false;
    var newServices = selectedMeals
        .filter(function (selectedMeal) { return selectedMeal.passengerId === passengerId; })
        .map(function (selectedMeal) {
        var count = selectedMeal.count, isSameService = selectedMeal.mealId === meal.id && selectedMeal.segmentId === segmentId;
        if (isSameService) {
            hasFound = true;
        }
        return {
            serviceId: selectedMeal.mealId,
            segmentIds: [selectedMeal.segmentId],
            count: isSameService ? count + 1 : count,
            confirmedCount: selectedMeal.confirmedCount
        };
    });
    if (!hasFound) {
        newServices.push({
            serviceId: meal.id,
            count: 1,
            segmentIds: [segmentId],
            confirmedCount: 0
        });
    }
    return [{ passengerId: passengerId, setServices: newServices }];
};
export var onMealRemove = function (meal, segmentId, passengerId, selectedMeals) {
    var newServices = selectedMeals
        .filter(function (selectedMeal) { return selectedMeal.passengerId === passengerId; })
        .map(function (selectedMeal) { return ({
        serviceId: selectedMeal.mealId,
        count: selectedMeal.mealId === meal.id && selectedMeal.segmentId === segmentId && selectedMeal.count > 0
            ? selectedMeal.count - 1
            : selectedMeal.count,
        segmentIds: [selectedMeal.segmentId],
        confirmedCount: selectedMeal.confirmedCount
    }); });
    return [{ passengerId: passengerId, setServices: newServices }];
};
export var getUserInfo = function (userInfo) {
    if (!userInfo || !(userInfo === null || userInfo === void 0 ? void 0 : userInfo.userProfile)) {
        return {
            lastName: '',
            firstName: '',
            email: ''
        };
    }
    return {
        lastName: getUserValue(userInfo.userProfile, TravellerFieldEnum.LastName),
        firstName: getUserValue(userInfo.userProfile, TravellerFieldEnum.FirstName),
        email: getUserValue(userInfo.userProfile, TravellerFieldEnum.Email)
    };
};
export var checkoutSelectedServicesStateFactory = function (order) {
    var selectedServices = {};
    order.travellers.forEach(function (traveller) {
        var _a, _b;
        var passengerServices = {};
        if ((_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services.length) {
            traveller.services.gdsServices.services.forEach(function (service) {
                var _a, _b, _c;
                var passengerService = {
                    segments: service.segmentIds,
                    number: service.count,
                    servicePayload: {
                        id: service.serviceId,
                        type: (_c = (_b = (_a = order.additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.find(function (additionalService) { return additionalService.id === service.serviceId; })) === null || _c === void 0 ? void 0 : _c.type,
                        confirmedCount: service.confirmedCount
                    }
                };
                if (passengerServices[service.serviceId]) {
                    passengerServices[service.serviceId].push(passengerService);
                }
                else {
                    passengerServices[service.serviceId] = [passengerService];
                }
            });
        }
        if ((_b = traveller.services.seats) === null || _b === void 0 ? void 0 : _b.length) {
            traveller.services.seats.forEach(function (service) {
                var _a;
                var seatService = {
                    segments: [service.segment.id],
                    servicePayload: {
                        id: (_a = service.seat.seatService) === null || _a === void 0 ? void 0 : _a.id,
                        type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                        letter: service.seat.letter,
                        rfisc: service.seat.rfisc,
                        price: service.seat.price,
                        row: service.row.toString(),
                        segmentId: service.segment.id,
                        number: "".concat(service.row).concat(service.letter),
                        isConfirmed: service.isConfirmed,
                        seatServices: service.seat.seatServices,
                        seatService: service.seat.seatService,
                        seatType: service.type
                    },
                    number: 1
                };
                if (!passengerServices["".concat(service.row).concat(service.letter)]) {
                    passengerServices["".concat(service.row).concat(service.letter)] = [seatService];
                }
                else {
                    passengerServices["".concat(service.row).concat(service.letter)].push(seatService);
                }
            });
        }
        selectedServices[traveller.id] = passengerServices;
    });
    return selectedServices;
};
export var getIncludedBaggageFromOrderTravellers = function (travellers) {
    var includedBaggages = [];
    travellers.forEach(function (traveller) {
        var _a, _b;
        return (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.brandIncludedServices) === null || _b === void 0 ? void 0 : _b.services.filter(function (services) { return services.service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage; }).forEach(function (service) {
            var _a, _b;
            var baggageService = service.service;
            var baggage = {
                id: service.serviceId,
                segmentIds: baggageService.allowedSegments,
                includedSegments: service.segmentIds,
                type: baggageService.baggageType,
                name: baggageService.name,
                description: baggageService.description,
                value: {
                    amount: parseInt((_b = (_a = baggageService.baggageWeight) === null || _a === void 0 ? void 0 : _a.split('x')) === null || _b === void 0 ? void 0 : _b[0]),
                    measurement: Measurement.Kilograms
                },
                size: baggageService.size,
                included: service.confirmedCount,
                price: baggageService.price,
                rfics: baggageService.rfisc,
                canBeAdded: false,
                allowedPassengers: [traveller.id]
            };
            includedBaggages.push(__assign(__assign({}, service), { baggage: baggage }));
        });
    });
    return includedBaggages;
};
export var getOrderPromoCodeInfoParams = function (order, formValues) {
    var travellersParam = [];
    var hasPromoCodes = false;
    order.travellers.forEach(function (traveller) {
        var travellerParamValues = [];
        var promoCode = null;
        traveller.values.forEach(function (travellerValue) {
            var _a;
            travellerParamValues.push({
                name: travellerValue.name,
                value: (_a = formValues['passengers'][traveller.id][travellerValue.name]) !== null && _a !== void 0 ? _a : null
            });
            if (travellerValue.type === TravellerFieldEnum.PromoCode) {
                promoCode = formValues['passengers'][traveller.id][travellerValue.name];
                if ((promoCode === null || promoCode === void 0 ? void 0 : promoCode.length) > 0) {
                    hasPromoCodes = true;
                }
            }
        });
        if (promoCode) {
            travellersParam.push({
                id: traveller.id,
                values: travellerParamValues,
                promoCode: promoCode
            });
        }
    });
    return hasPromoCodes
        ? {
            id: order.id,
            travellers: travellersParam
        }
        : null;
};
export var getTotalDiscount = function (promoCodesInfo) {
    var _a, _b;
    return (_b = (_a = promoCodesInfo === null || promoCodesInfo === void 0 ? void 0 : promoCodesInfo.travellers) === null || _a === void 0 ? void 0 : _a.reduce(function (total, tr) { var _a, _b; return total + ((_b = (_a = tr.promoCodeInfo) === null || _a === void 0 ? void 0 : _a.discount.amount) !== null && _b !== void 0 ? _b : 0); }, 0)) !== null && _b !== void 0 ? _b : 0;
};
export var getWithValidPromoCodesFormValues = function (formValues, promoCodesInfo, valuesNames) {
    if (promoCodesInfo.success) {
        return formValues;
    }
    var passengers = formValues['passengers'];
    var promoCodeValueName = valuesNames.get(TravellerFieldEnum.PromoCode);
    return __assign(__assign({}, formValues), { passengers: passengers.map(function (passenger, idx) {
            var _a;
            var _b;
            return (__assign(__assign({}, passenger), (_a = {}, _a[promoCodeValueName] = ((_b = promoCodesInfo.travellers[idx]) === null || _b === void 0 ? void 0 : _b.promoCodeInfo) ? passenger[promoCodeValueName] : null, _a)));
        }) });
};
export var getIsAllSeatsSelected = function (passengers, segments) {
    return passengers
        .filter(function (passenger) { return passenger.type !== AviaPassengerType.INF; })
        .every(function (passenger) {
        var selectedSeatsBySegment = getSelectedSeatsBySegment(passenger.seats);
        return segments.length === selectedSeatsBySegment.size;
    });
};
export var getIsAllExtraSeatsSelected = function (services, passengers, segments) {
    if (!services.length) {
        return true;
    }
    var passengersWithExtraSeat = passengers.filter(function (passenger) {
        return services.find(function (service) { return service.passengerId === passenger.id; });
    });
    return passengersWithExtraSeat.every(function (passenger) {
        var selectedSeatsBySegment = getSelectedSeatsBySegment(passenger.seats);
        return segments.length === selectedSeatsBySegment.size;
    });
};
export var mergeDuplicatedTravellerServices = function (services, keySelector) {
    var servicesByKeyMap = new Map();
    services === null || services === void 0 ? void 0 : services.forEach(function (service) {
        var _a, _b, _c, _d;
        var key = keySelector(service);
        if (servicesByKeyMap.has(key)) {
            var serviceToUpdate = servicesByKeyMap.get(key);
            servicesByKeyMap.set(key, __assign(__assign({}, serviceToUpdate), { count: ((_a = serviceToUpdate.count) !== null && _a !== void 0 ? _a : 0) + ((_b = service.count) !== null && _b !== void 0 ? _b : 0), confirmedCount: ((_c = serviceToUpdate.confirmedCount) !== null && _c !== void 0 ? _c : 0) + ((_d = service.confirmedCount) !== null && _d !== void 0 ? _d : 0) }));
        }
        else {
            servicesByKeyMap.set(key, service);
        }
    });
    return __spreadArray([], __read(servicesByKeyMap.values()), false);
};
export var isPetBaggage = function (baggage) {
    return [BaggageType.PetInCabin, BaggageType.PetInHandCarrier, BaggageType.PetInHold].includes(baggage.baggageType);
};
export var calculateCheckinAvailability = function (order) {
    var e_1, _a;
    var today = format(new Date(), API_DATE_FORMAT);
    var tomorrow = format(addDays(new Date(), 1), API_DATE_FORMAT);
    if (order.flight.segmentGroups.some(function (segmentGroup) {
        return segmentGroup.segments.some(function (segment) { return segment.departure.date === today; });
    })) {
        return true;
    }
    else {
        var segmentWithTomorrowDeparture = null;
        try {
            for (var _b = __values(order.flight.segmentGroups), _c = _b.next(); !_c.done; _c = _b.next()) {
                var segmentGroup = _c.value;
                segmentWithTomorrowDeparture = segmentGroup.segments.find(function (segment) { return segment.departure.date === tomorrow; });
                if (segmentWithTomorrowDeparture) {
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return (segmentWithTomorrowDeparture &&
            new Date().getHours() > Number(segmentWithTomorrowDeparture.departure.time.substring(0, 2)));
    }
};
export var getIsBrandIncludedService = function (order, serviceType) {
    return order.travellers.some(function (traveller) {
        var _a;
        return (_a = traveller.services.brandIncludedServices.services) === null || _a === void 0 ? void 0 : _a.some(function (service) { var _a; return ((_a = service.service) === null || _a === void 0 ? void 0 : _a.type) === serviceType && service.count > 0; });
    });
};
