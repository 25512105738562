import { __awaiter, __generator, __read } from "tslib";
import { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOrderServicesWithPurchasePredictionQuery } from '@websky/graphql';
import { getOrderId } from '../Checkout/store/order/selectors';
import { useConfig } from '../context';
import { getServiceWithMaxPrediction as getServiceWithMaxPredictionUtil } from './utils';
import { VarioqubContext } from '../Varioqub/varioqub';
export var useAiPurchasePredictionService = function () {
    var orderId = useSelector(getOrderId);
    var varioqubData = useContext(VarioqubContext);
    var _a = __read(useState(null), 2), service = _a[0], setService = _a[1];
    var _b = __read(useState(false), 2), isAddedService = _b[0], setIsAddedService = _b[1];
    var purchasePredictionThreshold = useConfig().global.purchasePredictionThreshold;
    var getOrderWithPurchasePrediction = useOrderServicesWithPurchasePredictionQuery({
        skip: true,
        fetchPolicy: 'no-cache'
    }).refetch;
    var onClearService = useCallback(function () { return setService(null); }, [setService]);
    var onDisagree = useCallback(function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onClearService();
                    return [4 /*yield*/, (callback === null || callback === void 0 ? void 0 : callback())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [onClearService]);
    var onChange = useCallback(function (payload, callback) {
        onClearService();
        callback === null || callback === void 0 ? void 0 : callback(payload);
    }, [onClearService]);
    var onAgree = useCallback(function (service, callback) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (callback === null || callback === void 0 ? void 0 : callback(service))];
                case 1:
                    _a.sent();
                    setIsAddedService(true);
                    onClearService();
                    return [2 /*return*/];
            }
        });
    }); }, [setIsAddedService, onClearService]);
    var onContinue = useCallback(function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        var data, serviceWithMaxPrediction;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(varioqubData === null || varioqubData === void 0 ? void 0 : varioqubData.get('SmartBox'))) return [3 /*break*/, 2];
                    return [4 /*yield*/, getOrderWithPurchasePrediction({ id: orderId })];
                case 1:
                    data = (_a.sent()).data;
                    if (data === null || data === void 0 ? void 0 : data.Order) {
                        serviceWithMaxPrediction = getServiceWithMaxPredictionUtil(data.Order.additionalServices);
                        if ((serviceWithMaxPrediction === null || serviceWithMaxPrediction === void 0 ? void 0 : serviceWithMaxPrediction.aiPurchasePrediction[0].probability) > purchasePredictionThreshold) {
                            setService(serviceWithMaxPrediction);
                            return [2 /*return*/];
                        }
                    }
                    _a.label = 2;
                case 2: return [4 /*yield*/, (callback === null || callback === void 0 ? void 0 : callback())];
                case 3:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [orderId, purchasePredictionThreshold, getOrderWithPurchasePrediction, varioqubData]);
    return {
        // data
        service: service,
        isOpen: !!service,
        isAddedService: isAddedService,
        // methods
        onClearService: onClearService,
        onDisagree: onDisagree,
        onChange: onChange,
        onAgree: onAgree,
        onContinue: onContinue
    };
};
