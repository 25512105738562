import { __read } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Header from './components/Header/Header';
import FormFields from './components/FormFields/FormFields';
import { useTheme } from '../../theme';
import { getPassengerDataLabel, requiredFillData } from '../utils';
import { useForm, useFormState } from 'react-final-form';
import PopupWrapper from './components/PopupWrapper/PopupWrapper';
import { useMediaQuery } from 'react-responsive';
import { capitalizeFirstLetterInName, getPassengerTypeLabel, MOBILE_MIN_WIDTH } from '../../utils';
import { fieldIsRequired, fieldsArrayToMap } from '../../Passenger/utils';
import WindowScope from './components/WindowScope/WindowScope';
import { Collapse } from '@material-ui/core';
import { DocTypes, TravellerFieldEnum } from '@websky/graphql';
import { Female, Male } from '../../Icons';
import { OverrideComponent } from '../../renderProps';
import { TravellerFields } from '../../TravellerForm/types';
var PassengerForm = function (props) {
    var t = useTranslation('Passenger').t;
    var theme = useTheme('PassengerForm').PassengerForm;
    var change = useForm().change;
    var _a = useFormState(), values = _a.values, submitFailed = _a.submitFailed;
    var label = React.useMemo(function () {
        var _a;
        var passengerValues = values.passengers[props.id];
        var firstName = passengerValues === null || passengerValues === void 0 ? void 0 : passengerValues[TravellerFields.FirstName];
        var lastName = passengerValues === null || passengerValues === void 0 ? void 0 : passengerValues[TravellerFields.LastName];
        var middleName = passengerValues === null || passengerValues === void 0 ? void 0 : passengerValues[TravellerFields.MiddleName];
        if (firstName && lastName) {
            return (React.createElement("span", { className: theme.label },
                React.createElement("span", { className: theme.label_lastName }, capitalizeFirstLetterInName(lastName)),
                ' ',
                React.createElement("span", { className: theme.label_firstName }, capitalizeFirstLetterInName(firstName)),
                ' ',
                middleName && (React.createElement("span", { className: theme.label_middleName }, capitalizeFirstLetterInName(middleName)))));
        }
        return "".concat(t('Passenger'), ", ").concat((_a = getPassengerTypeLabel(props.passengerType, props.supplierType)) === null || _a === void 0 ? void 0 : _a.toLowerCase());
    }, [props.fields, values]);
    var docType = React.useMemo(function () { var _a; return (_a = values.passengers[props.id]) === null || _a === void 0 ? void 0 : _a.docType; }, [values, props.id]);
    var isFieldRequired = React.useMemo(function () { var _a; return requiredFillData((_a = values === null || values === void 0 ? void 0 : values.passengers) === null || _a === void 0 ? void 0 : _a[props.id], values === null || values === void 0 ? void 0 : values.customer, props.fields, props.accompanyingFields); }, [values, props.fields]);
    var fieldsMap = React.useMemo(function () {
        return fieldsArrayToMap(props.fields);
    }, [props.fields]);
    var passengerFieldsMap = React.useMemo(function () {
        var _a;
        if (!isFieldRequired) {
            return getPassengerDataLabel((_a = values.passengers) === null || _a === void 0 ? void 0 : _a[props.id], fieldsMap);
        }
        return {};
    }, [isFieldRequired, values, fieldsMap]);
    React.useEffect(function () {
        if (!isFieldRequired) {
            props.onCorrectFilled(props.id);
        }
    }, [isFieldRequired]);
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var _b = __read(React.useState(false), 2), hasErrors = _b[0], setHasErrors = _b[1];
    var isOpened = isMobile && props.mobileOpenedPassengerId === props.id;
    var isReadonly = (props.isInputsReadonly || props.isReadonly) && !props.isChangePassengers;
    var numberIcon = React.useMemo(function () {
        var _a;
        if (props.isChangePassengers) {
            var gender = (_a = props.fields.find(function (field) { return field.type === TravellerFieldEnum.Gender; })) === null || _a === void 0 ? void 0 : _a.defaultValue;
            return gender ? (gender === 'M' ? Male : Female) : null;
        }
        return null;
    }, [props.fields, props.isChangePassengers]);
    var fields = React.useMemo(function () {
        if (props.isChangePassengers) {
            var isPassport_1 = docType === DocTypes.Passport;
            return props.fields.filter(function (field) {
                if (field.type === TravellerFieldEnum.LinkedTraveller) {
                    return false;
                }
                return !isPassport_1 || (isPassport_1 && field.type !== TravellerFieldEnum.DocExpiryDate);
            });
        }
        return props.fields;
    }, [docType, props.fields, props.isChangePassengers]);
    React.useEffect(function () {
        if (isMobile) {
            if (submitFailed && isFieldRequired && props.open) {
                setHasErrors(true);
                if (props.mobileOpenedPassengerId === null) {
                    props.onSetMobileOpenedPassengerId(props.id);
                }
            }
            else if (!isFieldRequired) {
                setHasErrors(false);
            }
        }
    }, [submitFailed, isMobile, isFieldRequired, props.open]);
    var onCloseHandler = function () { return props.onSetMobileOpenedPassengerId(null); };
    var onOpenHandler = function () { return props.onSetMobileOpenedPassengerId(props.id); };
    var onFillHandler = function (profile, type, fieldValue) {
        var _a, _b, _c, _d, _e, _f;
        if (type) {
            var docExpiryValidations = ((_a = props.fields.find(function (field) { return field.type === TravellerFieldEnum.DocExpiryDate; })) === null || _a === void 0 ? void 0 : _a.validations) || [];
            if ((type === TravellerFieldEnum.Nationality || type === TravellerFieldEnum.DocType) &&
                (((_c = (_b = values.passengers) === null || _b === void 0 ? void 0 : _b[props.id]) === null || _c === void 0 ? void 0 : _c.docExpiryDate) || ((_e = (_d = values.passengers) === null || _d === void 0 ? void 0 : _d[props.id]) === null || _e === void 0 ? void 0 : _e.nationality)) &&
                fieldIsRequired(values.passengers[props.id], docExpiryValidations)) {
                change("passengers[".concat(props.id, "].docExpiryDate"), '');
            }
        }
        (_f = props.onFill) === null || _f === void 0 ? void 0 : _f.call(props, profile, fieldValue);
    };
    return (React.createElement("div", { className: cn(theme.form, props.className), "data-passenger-type": props.passengerType, "data-passenger-id": props.id },
        React.createElement(WindowScope, { fieldsMap: fieldsMap, disabled: isReadonly },
            React.createElement(Header, { open: props.open, passengerId: props.id, label: label, numberIcon: numberIcon, onClick: onOpenHandler, correct: !isFieldRequired, passengerFieldsMap: passengerFieldsMap, showErrorNotice: hasErrors }),
            React.createElement(Collapse, { in: props.open },
                React.createElement(PopupWrapper, { inline: !isMobile, open: isOpened, onClose: onCloseHandler, correct: !isFieldRequired },
                    React.createElement(OverrideComponent, { componentProps: {
                            fields: fields,
                            passengerId: props.id,
                            passengerType: props.passengerType,
                            passengerDocType: docType,
                            selectedProfileId: props.selectedProfileId,
                            label: label,
                            inline: !isMobile,
                            disabled: isReadonly,
                            onTravellerSelect: onFillHandler,
                            isAuthorized: props.isAuthorized,
                            isChangeFiles: props.isChangeFiles,
                            isChangePassengers: props.isChangePassengers,
                            autofocus: props.autofocus,
                            accompanyingFields: props.accompanyingFields,
                            onSignUpLoyaltyProgram: props.onSignUpLoyaltyProgram,
                            onSwap: props.onSwap,
                            onToggleChangeFiles: props.onToggleChangeFiles
                        }, component: { PassengerFormFields: FormFields } }))))));
};
export default PassengerForm;
