import { __read, __rest } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Money } from '../../Money';
import { getLocale, initI18n } from '../../utils';
import PriceParts from './PriceParts/PriceParts';
import { useTheme } from '../../theme';
import GooglePay from './Gateway/GooglePay/GooglePay';
import Kaspi from './Gateway/Kaspi/Kaspi';
import Iframe from './Gateway/Iframe/Iframe';
import { PaymentType } from '../types';
import Link from '../../Link';
import Test from './Gateway/Test/Test';
import MediaQuery from '../../MediaQuery/MediaQuery';
import { arrowIcon, emptyCard } from '../Icons';
import Button from '../../Button';
import Notification from '../../Notification/components/Notification';
import { InfoMessageTypeEnum, PaymentProvider } from '@websky/graphql';
import Segments from './Segments/Segments';
import SummaryGroup from './Summary/SummaryGroup';
import Summary from './Summary/Summary';
import SummaryItem from './Summary/SummaryItem';
import PaymentTimeLimit from '../../PaymentTimeLimit';
import Cash from './Gateway/Cash/Cash';
import Invoice from './Gateway/Invoice/Invoice';
import Qiwi from './Gateway/Qiwi/Qiwi';
import EmptyWrapper from './EmptyWrapper/EmptyWrapper';
import PaymentResult from '../../PaymentResult';
import Modal from '../../Modal';
import SlideBottom from '../../SlideBottom';
import { useConfig } from '../../context';
import { OverrideComponent } from '../../renderProps';
import { Currency } from '../../enums';
import Tooltip from '../../Tooltip';
import { useSentryTranslation } from '../../hooks';
initI18n('PaymentForm');
var mastercard = require('../images/mastercard.svg');
var visa = require('../images/visa.svg');
var mir = require('../images/mir.svg');
var paylate = require('../images/paylate.svg');
var moneycare = require('../images/moneyCare.svg');
var invoicebox = require('../images/invoicebox.svg');
var invoiceboxRu = require('../images/invoicebox-ru.svg');
var iconKaspi = require('../images/kaspi.svg');
var applePay = require('../images/apple-pay.svg');
var googlePay1 = require('../images/google-pay-1.svg');
var qiwiIcon = require('../images/qiwi.svg');
var sberpay = require('../images/sberpay.svg');
var yandexpay = require('../images/yandex-pay.svg');
var sbp = require('../images/sbp.svg');
var idram = require('../images/idram.svg');
var vakifBank = require('../images/vakifbank.svg');
var unionPay = require('../images/unionPay.svg');
var PaymentForm = function (_a) {
    var _b, _c;
    var card = _a.card, onPaymentFrameLoaded = _a.onPaymentFrameLoaded, credit = _a.credit, googlePay = _a.googlePay, itransfer = _a.itransfer, kaspi = _a.kaspi, cash = _a.cash, fps = _a.fps, sbol = _a.sbol, invoice = _a.invoice, qiwi = _a.qiwi, priceToPay = _a.priceToPay, onSuccess = _a.onSuccess, onFail = _a.onFail, onTestPayment = _a.onTestPayment, onBackToOrder = _a.onBackToOrder, onPaymentMethodSelected = _a.onPaymentMethodSelected, paymentPage = _a.paymentPage, order = _a.order, onCompletePayment = _a.onCompletePayment, isPaymentAvailable = _a.isPaymentAvailable, orderIsRefetching = _a.orderIsRefetching, openFrameInNewTab = _a.openFrameInNewTab;
    var _d = __read(useState(paymentPage !== null && paymentPage !== void 0 ? paymentPage : null), 2), page = _d[0], setPage = _d[1];
    var _e = __read(useState(false), 2), isRedirecting = _e[0], setIsRedirecting = _e[1];
    var css = useTheme('PaymentForm').PaymentForm;
    var t = useTranslation('PaymentForm').t;
    var sentryT = useSentryTranslation('PaymentForm').t;
    var isPreOpened = false;
    var isOneMethodOnly = !googlePay && !onTestPayment && !itransfer && !kaspi && !qiwi && ((card && !credit) || (credit && !card));
    var iataCode = useConfig().global.companyInfo.iataCode;
    var hideCards = ['RM', 'A9', 'IO'].includes(iataCode);
    var locale = getLocale();
    isPreOpened = isOneMethodOnly || !!paymentPage;
    var resetPage = function () { return location.reload(); };
    var total = priceToPay.total, priceToPayWithoutTotal = __rest(priceToPay, ["total"]);
    var currency = total === null || total === void 0 ? void 0 : total.currency;
    useEffect(function () {
        onPaymentMethodSelected(page);
    }, [page, paymentPage]);
    var renderEmptyPaymentNotice = function () { return (React.createElement(Notification, { infoMessage: {
            text: t('Payment by this method is temporarily unavailable, please try refreshing the page or try again later.'),
            type: InfoMessageTypeEnum.Info,
            code: null
        }, status: InfoMessageTypeEnum.Info, type: "context" })); };
    var onStartRedirecting = function () { return setIsRedirecting(true); };
    var getGatewayTranslation = useCallback(function (method) {
        // [WEBSKY-4989]: display standard translations if the currency is different from RUB
        if (iataCode === '2S' && currency !== Currency.RUB) {
            return { title: null, header: null };
        }
        return {
            title: sentryT("Title_".concat(method.paymentProvider)),
            header: sentryT("Header_".concat(method.paymentProvider))
        };
    }, [iataCode, currency, sentryT]);
    var renderGatewayCards = useCallback(function (method, paymentType) {
        if ((!method.paymentProvider && paymentType === PaymentType.Card) ||
            // [WEBSKY-4989]: display common cards if the currency is different from RUB
            (iataCode === '2S' && currency !== Currency.RUB)) {
            return (React.createElement(React.Fragment, null,
                React.createElement("img", { src: sbp, alt: "SBP", className: cn(css.card, css.sbp) }),
                React.createElement("img", { src: sberpay, alt: "SberPay", className: cn(css.card, css.sberpay) }),
                React.createElement("img", { src: mastercard, alt: "Mastercard", className: cn(css.card, css.mastercard) }),
                React.createElement("img", { src: visa, alt: "VISA", className: cn(css.card, css.visa) }),
                React.createElement("img", { src: mir, alt: "\u041C\u0418\u0420", className: cn(css.card, css.mir) }),
                React.createElement("img", { src: applePay, alt: "ApplePay", className: cn(css.card, css.applepay) }),
                React.createElement("img", { src: googlePay1, alt: "GooglePay", className: cn(css.card, css.googlepay) }),
                React.createElement("img", { src: yandexpay, alt: "YandexPay", className: cn(css.card, css.yandexpay) }),
                React.createElement("img", { src: vakifBank, alt: "VakifBank", className: cn(css.card, css.vakifbank) }),
                React.createElement("img", { src: paylate, alt: "PaylateAsCard", className: cn(css.card, css.paylate_asCard) }),
                React.createElement("img", { src: unionPay, alt: "UnionPay", className: cn(css.card, css.unionpay) })));
        }
        switch (method.paymentProvider) {
            case PaymentProvider.Idram:
                return React.createElement("img", { src: idram, alt: "Idram", className: cn(css.idram, css.visible) });
            case PaymentProvider.PayLate:
                return React.createElement("img", { src: paylate, alt: "Paylate", className: cn(css.card, css.paylate, css.visible) });
            case PaymentProvider.MoneyCarePro:
                return React.createElement("img", { src: moneycare, alt: "Paylate", className: cn(css.paylate, css.visible) });
            case PaymentProvider.YandexPay:
                return React.createElement("img", { src: yandexpay, alt: "YandexPay", className: cn(css.card, css.yandexpay, css.visible) });
            default:
                return React.createElement(React.Fragment, null, emptyCard);
        }
    }, [iataCode, currency]);
    return (React.createElement("div", { className: cn(css.wrapper, (_b = {},
            _b[css.oneMethod] = isOneMethodOnly,
            _b)), "data-currency": currency },
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(Modal, { open: !page && isPaymentAvailable === false, classes: { paper: css.payment__result } },
                React.createElement(PaymentResult, { result: "fail", onClick: resetPage, onBackToOrder: onBackToOrder, isModal: true }))),
        React.createElement(MediaQuery, { maxWidth: 'mobile' },
            React.createElement(SlideBottom, { isOpen: !page && isPaymentAvailable === false, onClose: onBackToOrder },
                React.createElement(PaymentResult, { result: "fail", onClick: resetPage, onBackToOrder: onBackToOrder, isModal: true }))),
        React.createElement(Summary, { className: css.details },
            order && (React.createElement(SummaryGroup, { className: css.summaryGroup_order },
                React.createElement(Segments, { order: order }))),
            React.createElement(SummaryGroup, { className: css.summaryGroup_priceParts },
                React.createElement(OverrideComponent, { componentProps: {
                        prices: priceToPayWithoutTotal
                    }, component: { PriceParts: PriceParts } })),
            React.createElement(SummaryGroup, { className: css.summaryGroup_total },
                React.createElement(SummaryItem, { className: css.details__totalItem, price: React.createElement("h2", { className: css.details__total },
                        t('Total'),
                        ": ",
                        React.createElement(Money, { money: total })) }, !!onBackToOrder && (React.createElement(Button, { className: css.details__button, variant: "text", size: "small", onClick: onBackToOrder }, t('Edit order')))))),
        React.createElement("div", { className: css.content },
            React.createElement("h3", { className: css.content__title },
                React.createElement(MediaQuery, { maxWidth: 'mobile' }, page && !isOneMethodOnly && (React.createElement(Link, { className: css.content__title__back, action: resetPage }, arrowIcon))),
                React.createElement("span", null, page ? page.title : t('Choose a convenient payment method')),
                React.createElement(MediaQuery, { minWidth: 'mobile' }, page && !isOneMethodOnly && (React.createElement(Button, { size: "small", variant: "text", className: css.content__title__back, onClick: resetPage }, t('Other methods')))),
                isOneMethodOnly && !hideCards && (React.createElement("div", { className: cn(css.cards, css.cards_header) },
                    React.createElement("img", { src: mastercard, alt: "Mastercard", className: cn(css.card, css.mastercard) }),
                    React.createElement("img", { src: visa, alt: "VISA", className: cn(css.card, css.visa) }),
                    React.createElement("img", { src: mir, alt: "\u041C\u0418\u0420", className: cn(css.card, css.mir) })))),
            React.createElement("div", { className: cn(css.content__tooltip) },
                React.createElement(Tooltip, { title: React.createElement("div", { className: cn(css.content__tooltip__text) }, t('Payment problems hint')), tooltipClassName: cn(css.tooltip), enterTouchDelay: 0 },
                    React.createElement("span", null, t('Problems with payment?')))),
            page && page.description && React.createElement("p", { className: css.description }, page.description),
            (order === null || order === void 0 ? void 0 : order.timelimit) && (React.createElement(PaymentTimeLimit, { variant: "normal", className: css.timelimit, orderIsBooked: true, date: new Date(order.timelimit), orderId: order.id })),
            React.createElement("ul", { className: cn(css.gateways, (_c = {},
                    _c[css.gateways_hidden] = !!page,
                    _c)) },
                React.createElement(EmptyWrapper, { emptyRender: renderEmptyPaymentNotice, isRefetching: orderIsRefetching },
                    googlePay && (React.createElement(GooglePay, { price: priceToPay === null || priceToPay === void 0 ? void 0 : priceToPay.total, onClick: setPage, onSuccess: onSuccess, onFail: onFail })),
                    card && (React.createElement(Iframe, { openFrameInNewTab: openFrameInNewTab, type: PaymentType.Card, frameInfo: card, onClick: setPage, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Card, onPaymentFrameLoaded: onPaymentFrameLoaded, gatewayTitle: getGatewayTranslation(card).title, headerTitle: getGatewayTranslation(card).header, children: React.createElement("div", { className: css.cards }, renderGatewayCards(card, PaymentType.Card)) })),
                    kaspi && (React.createElement(Kaspi, { isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Kaspi, frameInfo: kaspi, headerTitle: t('Kaspi'), onClick: setPage },
                        React.createElement("div", { className: css.cards },
                            React.createElement("img", { src: iconKaspi, alt: t('Kaspi'), className: cn(css.card, css.kaspi) })))),
                    credit && (React.createElement(Iframe, { openFrameInNewTab: openFrameInNewTab, type: PaymentType.Credit, frameInfo: credit, onClick: setPage, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Credit, gatewayTitle: getGatewayTranslation(credit).title, headerTitle: getGatewayTranslation(credit).header, children: React.createElement("div", { className: css.cards }, renderGatewayCards(credit, PaymentType.Credit)) })),
                    itransfer && (React.createElement(Iframe, { openFrameInNewTab: openFrameInNewTab, type: PaymentType.Itransfer, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Itransfer, frameInfo: itransfer, onClick: setPage, headerTitle: t('Invoicebox'), gatewayTitle: t('Invoicebox'), variant: "page" },
                        React.createElement("div", { className: css.cards },
                            React.createElement("img", { src: locale === 'ru' ? invoiceboxRu : invoicebox, alt: t('Invoicebox'), className: cn(css.card, css.invoicebox) })))),
                    sbol && (React.createElement(Iframe, { type: PaymentType.Sbol, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Sbol, frameInfo: sbol, onClick: setPage, headerTitle: t('SberPay'), gatewayTitle: t('SberPay'), variant: "page", onStartRedirecting: onStartRedirecting },
                        React.createElement("div", { className: css.cards },
                            React.createElement("img", { src: sberpay, alt: t('SberPay'), className: cn(css.icon) })))),
                    fps && (React.createElement(Iframe, { type: PaymentType.Fps, isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Fps, frameInfo: fps, onClick: setPage, headerTitle: t('FPS'), gatewayTitle: t('FPS'), variant: "page", onStartRedirecting: onStartRedirecting },
                        React.createElement("div", { className: css.cards },
                            React.createElement("img", { src: sbp, alt: t('FPS'), className: cn(css.icon) })))),
                    cash && (React.createElement(Cash, { isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Cash, frameInfo: cash, headerTitle: t('Cash'), type: PaymentType.Cash, onClick: setPage, onCompletePayment: onCompletePayment })),
                    invoice && (React.createElement(Invoice, { isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Invoice, frameInfo: invoice, headerTitle: t('Invoice'), type: PaymentType.Invoice, onClick: setPage, onCompletePayment: onCompletePayment })),
                    qiwi && (React.createElement(Qiwi, { isPreOpened: isPreOpened && (page === null || page === void 0 ? void 0 : page.type) === PaymentType.Qiwi, frameInfo: qiwi, headerTitle: t('QIWI'), onClick: setPage },
                        React.createElement("div", { className: css.cards },
                            React.createElement("img", { src: qiwiIcon, alt: t('QIWI'), className: css.qiwi })))),
                    onTestPayment && React.createElement(Test, { onClick: onTestPayment }))),
            page && (React.createElement("div", null,
                React.createElement(EmptyWrapper, { emptyRender: renderEmptyPaymentNotice, isRefetching: orderIsRefetching || isRedirecting }, page.content))))));
};
export default PaymentForm;
