import { __awaiter, __generator } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useSocialAuthQuery, OauthServiceType } from '@websky/graphql';
import { useTheme } from '../../theme';
import { useConfig } from '../../context';
import { useTinkoffCallback } from './hooks';
import { getBackRedirectUrl, setSocialAuthRedirectUrl } from './utils';
import { icons } from '../../Account/components/SocialAuth/icons';
import SimpleLoader from '../../SimpleLoader';
var SocialAuth = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'common' : _b, onClick = _a.onClick;
    var socialAuthMethods = useConfig().global.socialAuthMethods;
    var css = useTheme('SocialAuth').SocialAuth;
    var socialAuth = useSocialAuthQuery({ skip: true });
    var redirectToService = function (service) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (onClick) {
                        // Useful for analytics goals.
                        onClick(service);
                    }
                    return [4 /*yield*/, socialAuth.refetch({
                            params: {
                                service: service,
                                backRedirectUrl: getBackRedirectUrl(service)
                            }
                        })];
                case 1:
                    data = (_a.sent()).data;
                    setSocialAuthRedirectUrl(service);
                    if (data.OauthRedirectUrl.message) {
                        window.open(data.OauthRedirectUrl.message, '_top');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var isTinkoffLoading = useTinkoffCallback().isLoading;
    return (React.createElement("div", { className: css.wrapper, "data-social-auth": true },
        isTinkoffLoading && React.createElement(SimpleLoader, null),
        socialAuthMethods
            .filter(function (method) { return method.isAvailable; })
            .map(function (method) {
            var _a;
            return (React.createElement(ButtonBase, { key: method.service, "data-oauth-type": method.service, className: cn(css.button, (_a = {},
                    _a[css.button_square] = variant === 'square',
                    _a[css.button_vk] = method.service === OauthServiceType.Vkontakte,
                    _a[css.button_light] = method.service === OauthServiceType.Vkontakte ||
                        method.service === OauthServiceType.Facebook ||
                        method.service === OauthServiceType.TinkoffID,
                    _a[css.button_facebook] = method.service === OauthServiceType.Facebook,
                    _a[css.button_google] = method.service === OauthServiceType.Google,
                    _a)), onClick: function () { return redirectToService(OauthServiceType[method.service]); } }, icons[method.service]));
        })));
};
export default SocialAuth;
