import { __read } from "tslib";
import React, { useState, useEffect } from 'react';
import { useTheme } from '../../../theme';
import cn from 'classnames';
import { Female, Male } from '../../../Icons';
var defaultOptions = [
    {
        key: 'Male',
        value: 'Male',
        icon: Male
    },
    {
        key: 'Female',
        value: 'Female',
        icon: Female
    }
];
var SexSelector = function (_a) {
    var className = _a.className, required = _a.required, disabled = _a.disabled, activeSex = _a.activeSex, _b = _a.options, options = _b === void 0 ? defaultOptions : _b, onChange = _a.onChange, onBlur = _a.onBlur;
    var css = useTheme('BaseComponents').SexSelector;
    var _c = __read(useState(activeSex), 2), sex = _c[0], setSex = _c[1];
    useEffect(function () {
        if (activeSex !== sex) {
            setSex(activeSex);
        }
    }, [activeSex]);
    var onChangeHandler = function (e) {
        setSex(e.target.value);
        onChange === null || onChange === void 0 ? void 0 : onChange(e.target.value);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
    };
    return (React.createElement("div", { className: cn(css.container, className) }, options.map(function (option) {
        var _a;
        return (React.createElement("label", { className: cn(css.label, (_a = {},
                _a[css.required] = required && !sex,
                _a[css.disabled] = disabled,
                _a[css.checked] = option.key === sex,
                _a)), htmlFor: option.key, key: option.key },
            React.createElement("span", null, option.icon),
            React.createElement("input", { id: option.key, type: "radio", className: cn(css.input), checked: option.key === sex, value: option.key, disabled: disabled, onChange: onChangeHandler })));
    })));
};
export default SexSelector;
