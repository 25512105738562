import { __read } from "tslib";
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import Categories from '../Categories/Categories';
import Warning from '../Warning/Warning';
import { useTheme } from '../../../theme';
import { ALL_DISHES_CATEGORY_ID, SELECTED_DISHES_CATEGORY_ID } from '../../constants';
import { getCategories, removeDuplicatesByName } from '../../utils';
import { MealViewMode } from '../../../Meal/types';
import { useConfig } from '../../../context';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { isDemo } from '../../../utils';
import { useTranslation } from 'react-i18next';
import InfoMessages from '../../../InfoMessages';
import { InfoMessagePositionsEnum } from '@websky/graphql';
var ServiceWrapper = function (_a) {
    var _b, _c;
    var activeSegmentId = _a.activeSegmentId, meals = _a.meals, _d = _a.selectedMeals, selectedMeals = _d === void 0 ? [] : _d, _e = _a.initialSelectedMeals, initialSelectedMeals = _e === void 0 ? [] : _e, mealRenderer = _a.mealRenderer, recommendedMeals = _a.recommendedMeals;
    var t = useTranslation('MealService').t;
    var css = useTheme('MealService').ServiceWrapper;
    var _f = __read(useState(false), 2), hasSelectedMeals = _f[0], setHasSelectedMeals = _f[1];
    var _g = useConfig().Engine, mealWarning = _g.mealWarning, mealDefaultFilterButtonPlacement = _g.mealDefaultFilterButtonPlacement;
    var categories = useMemo(function () { return getCategories(meals, activeSegmentId); }, [meals, activeSegmentId]);
    var _h = __read(useState(mealDefaultFilterButtonPlacement === 'end' ? 0 : ALL_DISHES_CATEGORY_ID), 2), category = _h[0], setCategory = _h[1];
    var mealIsSelected = function (meal) {
        return initialSelectedMeals.find(function (selectedMeal) {
            return meal.id === selectedMeal.mealId && selectedMeal.segmentId === activeSegmentId;
        });
    };
    var mealIsConfirmed = function (meal) {
        return selectedMeals.find(function (selectedMeal) {
            return (meal.id === selectedMeal.mealId &&
                selectedMeal.segmentId === activeSegmentId &&
                selectedMeal.confirmedCount);
        });
    };
    var filteredMeals = useMemo(function () {
        var filtered = meals.filter(function (meal) {
            return (meal.segmentsId.includes(activeSegmentId) &&
                (category === ALL_DISHES_CATEGORY_ID ||
                    meal.category === categories[category] ||
                    (hasSelectedMeals && category === SELECTED_DISHES_CATEGORY_ID && mealIsSelected(meal))));
        });
        return isDemo ? removeDuplicatesByName(filtered) : filtered;
    }, [meals, activeSegmentId, category, selectedMeals]);
    var confirmedMeals = useMemo(function () {
        var filtered = meals.filter(function (meal) {
            return meal.segmentsId.includes(activeSegmentId) && mealIsConfirmed(meal);
        });
        return isDemo ? removeDuplicatesByName(filtered) : filtered;
    }, [meals, activeSegmentId, category, selectedMeals]);
    var numOfSelectedMeals = useMemo(function () {
        var result = 0;
        selectedMeals.forEach(function (meal) {
            if (meal.segmentId === activeSegmentId) {
                result += meal.count - meal.confirmedCount;
            }
        });
        return result;
    }, [selectedMeals, activeSegmentId]);
    useEffect(function () {
        setHasSelectedMeals(initialSelectedMeals.some(function (selectedMeal) { return selectedMeal.segmentId === activeSegmentId; }));
        // if user remove meal on selected dishes tab, return to first category
        if (category === SELECTED_DISHES_CATEGORY_ID && !hasSelectedMeals) {
            setCategory(ALL_DISHES_CATEGORY_ID);
        }
    }, [selectedMeals, activeSegmentId, category, hasSelectedMeals]);
    useEffect(function () {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.MealsList, { segmentId: activeSegmentId });
    }, [activeSegmentId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Categories, { categories: categories, onSelect: setCategory, selectedCategory: category, numOfSelectedMeals: numOfSelectedMeals, defaultGroupPlacement: mealDefaultFilterButtonPlacement }),
        React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.meal, className: css.infoMessage, itemHtmlClassName: css.infoMessage__htmlMessage }),
        mealWarning && React.createElement(Warning, { warning: mealWarning }),
        React.createElement("div", { className: cn(css.meals, (_b = {},
                _b[css.meals_singleMeal] = filteredMeals.length === 1,
                _b)) }, filteredMeals.map(function (meal) { return mealRenderer(meal, activeSegmentId, filteredMeals.length === 1); })),
        (confirmedMeals === null || confirmedMeals === void 0 ? void 0 : confirmedMeals.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(css.meals_purchased) }, t('Purchased')),
            React.createElement("div", { className: cn(css.meals, (_c = {},
                    _c[css.meals_singleMeal] = confirmedMeals.length === 1,
                    _c)) }, confirmedMeals.map(function (meal) {
                return mealRenderer(meal, activeSegmentId, confirmedMeals.length === 1, MealViewMode.confirmed);
            }))))));
};
export default ServiceWrapper;
