import { __read } from "tslib";
import * as React from 'react';
import MuiSelect from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../theme';
export var Select = function (props) {
    var _a, _b;
    var _c = __read(React.useState(props.value ? props.value : ''), 2), value = _c[0], setValue = _c[1];
    var t = useTranslation('Passenger').t;
    var inputCss = useTheme('Input').Input;
    var css = useTheme('Passenger').Select;
    var variant = props.variant || 'filled';
    var onChange = function (e) {
        var _a;
        if (typeof e.target.value === 'string') {
            var selected = e.target.value;
            setValue(selected);
            (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, e.target.value, e);
        }
    };
    useEffect(function () {
        setValue(props.value);
    }, [props.value]);
    useEffect(function () {
        if ((props.options.length === 1 || props.selectFirstOptionByDefault) && !props.value) {
            var optionValue = props.options[0].key;
            var changeEvent = {
                target: {
                    value: optionValue
                }
            };
            setValue(optionValue);
            props === null || props === void 0 ? void 0 : props.onChange(optionValue, changeEvent);
        }
    }, [props.options, props.selectFirstOptionByDefault, props.value]);
    return (React.createElement(FormControl, { className: cn(props.className), error: !!props.error, variant: variant },
        React.createElement(InputLabel, { htmlFor: "select-".concat(props.name), classes: {
                root: cn(inputCss.textField__root, css.label, (_a = {},
                    _a[inputCss.textField_standard] = variant === 'standard',
                    _a)),
                focused: cn(inputCss.textField, inputCss.textField_focused),
                error: inputCss.label_error,
                shrink: inputCss.label_shrink,
                outlined: inputCss.textField_outlined,
                filled: inputCss.textField_filled
            } }, props.label),
        React.createElement(MuiSelect, { renderValue: props.renderValue, value: value, name: props.name, MenuProps: props.menuProps, input: React.createElement(Input, { classes: {
                    disabled: inputCss.disabled,
                    // underline: css.select,
                    root: cn(css.input, inputCss.input, inputCss.real_input, (_b = {},
                        _b[inputCss.input_filled] = variant === 'filled',
                        _b[inputCss.input_outline] = variant === 'outlined',
                        _b[inputCss.input_standard] = variant === 'standard',
                        _b[css.input_standard] = variant === 'standard',
                        _b[css.input_readonly] = props.readonly,
                        _b)),
                    error: css.error,
                    focused: inputCss.input_focused,
                    underline: inputCss.underline,
                    input: css.real_input
                }, name: props.name, id: "select-".concat(props.name), disableUnderline: variant !== 'standard', readOnly: props.readonly }), classes: {
                icon: css.icon
            }, disabled: props.disabled, onChange: onChange, onBlur: props.onBlur, readOnly: props.readonly }, props.options.map(function (option) {
            if (props.renderOption) {
                return props.renderOption(option);
            }
            else {
                return (React.createElement(MenuItem, { key: option.key, value: option.key, className: css.option }, props.translateValue ? t(option.value) : option.value));
            }
        })),
        props.error && React.createElement(FormHelperText, { classes: { root: css.helperText } }, props.error)));
};
