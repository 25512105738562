import { __assign, __read } from "tslib";
import * as React from 'react';
import { TravellerFieldEnum } from '@websky/graphql';
import { TravellerFields } from '../TravellerForm/types';
import { getActualPassengerValues } from './utils';
import { getUserValue } from '../utils';
export var reducer = function (state, action) {
    if (action.type === 'fill') {
        return __assign(__assign({}, state), { passengers: state.passengers.map(function (passenger, index) {
                if (index === action.payload.id) {
                    var values = __assign(__assign({}, passenger), { profileId: action.payload.profileId });
                    for (var key in values) {
                        var value = values[key];
                        var isDocField = [TravellerFields.DocExpiryDate, TravellerFields.DocNumber].includes(key);
                        if (action.payload.values.get(key) || isDocField) {
                            value = action.payload.values.get(key);
                        }
                        values[key] = value;
                    }
                    return values;
                }
                return passenger;
            }) });
    }
    else if (action.type === 'swap') {
        return __assign(__assign({}, state), { passengers: state.passengers.map(function (passenger, index) {
                var _a;
                if (index === action.payload.id) {
                    return __assign(__assign({}, passenger), (_a = {}, _a[TravellerFields.FirstName] = passenger[TravellerFields.LastName], _a[TravellerFields.LastName] = passenger[TravellerFields.FirstName], _a));
                }
                return passenger;
            }) });
    }
    return state;
};
export var createInitialState = function (passengers, customer) {
    var customerValues = {
        email: '',
        phone: '',
        accompanyingPersonName: '',
        accompanyingPersonTicketNumber: '',
        accompanyingCheck: false
    };
    if (customer) {
        customerValues = __assign(__assign({}, customerValues), { email: getUserValue(customer, TravellerFieldEnum.Email), phone: getUserValue(customer, TravellerFieldEnum.Phone) });
    }
    var passengerValues = passengers.map(function (passenger) {
        var _a;
        var values = {
            type: passenger.type,
            disabilities: { enabled: false, values: {} },
            files: { changeFiles: [] }
        };
        (_a = passenger.values) === null || _a === void 0 ? void 0 : _a.forEach(function (value) {
            var _a, _b, _c, _d;
            var actualValue = value.value;
            var isNationality = value.type === TravellerFieldEnum.Nationality;
            var validationOption = (_c = (_b = (_a = value.validationRules) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.options) === null || _c === void 0 ? void 0 : _c.find(function (option) { return option.value === value.value; });
            if (isNationality && !validationOption && ((_d = value.validationRules) === null || _d === void 0 ? void 0 : _d.length)) {
                actualValue = value.validationRules[0].options[0].value;
            }
            values[value.name] = actualValue;
        });
        return values;
    });
    return {
        customer: customerValues,
        passengers: passengerValues
    };
};
export var usePassengerForm = function (passengers, customer, isInternationalFlight, isFreezeOrder) {
    var _a = __read(React.useReducer(reducer, createInitialState(passengers, customer)), 2), state = _a[0], dispatch = _a[1];
    var selectedProfileIdsByPassenger = React.useMemo(function () {
        var selectedProfileIdsByPassengerMap = new Map();
        if (state.passengers.length) {
            state.passengers.forEach(function (passenger, index) {
                if (passenger.profileId) {
                    selectedProfileIdsByPassengerMap.set(index, passenger.profileId);
                }
            });
        }
        return selectedProfileIdsByPassengerMap;
    }, [state]);
    // TODO: fix passenger index to real passenger id
    var onFillValues = React.useCallback(function (profile, id, document) {
        if (profile) {
            var actualPassengerValues = getActualPassengerValues(passengers, profile, id, document, isInternationalFlight, isFreezeOrder);
            dispatch({ type: 'fill', payload: { id: id, profileId: profile.id, values: actualPassengerValues } });
        }
    }, [state, passengers, isInternationalFlight, isFreezeOrder, dispatch]);
    var onSwapNames = React.useCallback(function (id) { return dispatch({ type: 'swap', payload: { id: id } }); }, [state]);
    return { state: state, selectedProfileIdsByPassenger: selectedProfileIdsByPassenger, onFillValues: onFillValues, onSwapNames: onSwapNames };
};
