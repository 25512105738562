import { __assign, __awaiter, __generator, __read, __values } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { Form } from 'react-final-form';
import { useTheme } from '../../theme';
import Button from '../../BaseComponents/Button';
import { FormStages, TravellerFields } from '../types';
import { useTranslation } from 'react-i18next';
import Preferences from './Preferences/Preferences';
import { TravellerTypes } from '../../Traveller/types';
import { successLoadingIcon as successIcon } from '../Icons';
import { Add, DeleteIcon, Tooltip } from '../../index';
import TravellerField from './TravellerField/TravellerField';
import { validate } from '../../Passenger/utils';
import { InputType } from '../../Passenger/types';
import DocumentForm from '../../DocumentForm';
import { convertDocuments } from '../utils';
import { TravellerFieldEnum, useDocumentsValidationsQuery } from '@websky/graphql';
import { clearPhoneNumber } from '../../PhoneInput/utils';
import { convertGenderToCorrectValue } from '../../utils';
import ModalConfirm from '../../ModalConfirm';
import { useToggleable } from '../../hooks';
var TravellerForm = function (props) {
    var _a, _b, _c;
    var css = useTheme('TravellerForm').TravellerForm;
    var t = useTranslation('Traveller').t, tPassengers = useTranslation('Passenger').t, _d = __read(useState(FormStages.Default), 2), stage = _d[0], setStage = _d[1], _e = __read(useState(false), 2), openedErrorTooltip = _e[0], setOpenedErrorTooltip = _e[1];
    var documentsValidations = useDocumentsValidationsQuery().data;
    var documentValidators = (_b = (_a = documentsValidations === null || documentsValidations === void 0 ? void 0 : documentsValidations.DefaultTravellerProfile) === null || _a === void 0 ? void 0 : _a.documents) === null || _b === void 0 ? void 0 : _b[0];
    var onSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStage(FormStages.Loading);
                    return [4 /*yield*/, props.onSubmit(__assign(__assign({}, values), { citizenship: values['nationality'], phone: values['phone'] && clearPhoneNumber(values['phone']), documents: values['documents'].map(function (document) { return ({
                                number: document.docNumber,
                                type: document.docType,
                                expiryDate: document.docExpiryDate
                            }); }), preferences: undefined }))];
                case 1:
                    data = _a.sent();
                    if (data && props.onClose) {
                        setTimeout(props.onClose, 500);
                    }
                    return [2 /*return*/, data];
            }
        });
    }); };
    var fieldSets = {
        originalNames: ['lastName', 'firstName', 'middleName'],
        personInfo: ['nationality', 'birthDate', 'gender']
    };
    var validateForm = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var errors, modifiedValues;
        var _a;
        return __generator(this, function (_b) {
            errors = {};
            modifiedValues = __assign(__assign({}, values), { docType: (_a = values['documents'][0]) === null || _a === void 0 ? void 0 : _a.docType });
            props.traveller.values.forEach(function (field) {
                var fieldValue = values[field.name];
                var inputType = field.name === TravellerFields.BirthDate
                    ? InputType.Date
                    : field.name === TravellerFields.Gender
                        ? InputType.Switch
                        : field.name === TravellerFields.Nationality
                            ? InputType.Select
                            : field.name === TravellerFields.Phone
                                ? InputType.Phone
                                : InputType.Text;
                errors[field.name] = validate(fieldValue, modifiedValues, inputType, field.validationRules);
                if (errors[field.name]) {
                    errors[field.name] = tPassengers(errors[field.name]);
                }
            });
            return [2 /*return*/, errors];
        });
    }); };
    var renderLoading = function (button) {
        return stage === FormStages.Loading ? (React.createElement("div", { className: css.loader },
            React.createElement("div", { className: css.loader_spinner }))) : stage === FormStages.Success ? (React.createElement("div", { className: cn(css.loader, css.success) },
            React.createElement("div", { className: css.success__icon },
                successIcon,
                " "))) : (button);
    };
    var fieldValuesObject = React.useMemo(function () {
        var e_1, _a;
        var _b;
        var values = {};
        if (props.traveller.values) {
            try {
                for (var _c = __values(props.traveller.values), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var field = _d.value;
                    if (field.type === TravellerFieldEnum.Gender) {
                        // [WEBSKY-5402]: convert gender to correct value
                        values[field.name] = convertGenderToCorrectValue(field.value);
                    }
                    else {
                        values[field.name] = field.value;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            values['documents'] = (_b = convertDocuments(props.traveller.documents)) === null || _b === void 0 ? void 0 : _b.map(function (document) {
                return __assign({}, document);
            });
        }
        return values;
    }, [props.traveller]);
    var documentsMutator = function (params, state) {
        if (params[0]['type'] === 'remove') {
            state.formState.values['documents'] = state.formState.values['documents'].filter(function (doc, index) { return index !== params[0]['index']; });
        }
        else if (params[0]['type'] === 'push') {
            state.formState.values['documents'].push({
                docType: '',
                docNumber: '',
                docExpiryDate: ''
            });
        }
    };
    var nationalityMutator = function (params, state) {
        state.formState.values['documents'] = state.formState.values['documents'].map(function (document) {
            return __assign(__assign({}, document), { docType: '' });
        });
    };
    var sortedNationalities = (_c = props.traveller.values) === null || _c === void 0 ? void 0 : _c.find(function (field) { return field.type === TravellerFieldEnum.Nationality; }).validationRules[0].options.map(function (option) { return (__assign(__assign({}, option), { priority: Number(option.priority) })); }).sort(function (a, b) { return b.priority - a.priority; });
    var _f = useToggleable(false), isDeletionDialogOpen = _f.isOpen, openDeletionDialog = _f.open, closeDeletionDialog = _f.close;
    return (React.createElement("div", { className: css.form },
        React.createElement(Form, { onSubmit: onSubmit, validate: validateForm, initialValues: props.traveller ? fieldValuesObject : { gender: 'Male' }, keepDirtyOnReinitialize: true, mutators: {
                documentsMutator: documentsMutator,
                nationalityMutator: nationalityMutator
            }, render: function (_a) {
                var _b;
                var handleSubmit = _a.handleSubmit, form = _a.form, hasValidationErrors = _a.hasValidationErrors, values = _a.values;
                return (React.createElement("form", { onSubmit: function (event) {
                        if (!hasValidationErrors) {
                            handleSubmit(event).then(function (res) {
                                if (res) {
                                    setStage(FormStages.Success);
                                    if (props.type !== TravellerTypes.PersonalDetails) {
                                        setTimeout(function () {
                                            form.reset();
                                            setStage(FormStages.Default);
                                        }, 500);
                                    }
                                    else {
                                        setTimeout(function () {
                                            setStage(FormStages.Default);
                                        }, 500);
                                    }
                                }
                                else {
                                    setStage(FormStages.Default);
                                    setOpenedErrorTooltip(true);
                                    setTimeout(function () {
                                        setOpenedErrorTooltip(false);
                                    }, 5000);
                                }
                            });
                        }
                        else {
                            handleSubmit(event);
                        }
                    }, className: css.traveller__form },
                    React.createElement("div", { className: css.container }, Object.keys(fieldSets).map(function (fieldSet, index) {
                        var _a;
                        var fields = fieldSets[fieldSet];
                        if (fieldSet === 'personInfo') {
                            return (React.createElement("div", { key: index, className: css.row },
                                React.createElement("div", { className: css.item },
                                    React.createElement(TravellerField, { fieldName: fields[0], options: sortedNationalities })),
                                React.createElement("div", { className: cn(css.item, css.item__inline) },
                                    React.createElement(TravellerField, { fieldName: fields[1] })),
                                React.createElement("div", { className: cn(css.item, css.item__inline) },
                                    React.createElement(TravellerField, { fieldName: fields[2], options: ((_a = props.traveller.values) === null || _a === void 0 ? void 0 : _a.find(function (field) { return field.name === fields[2]; }).validationRules[0].options) || [] }))));
                        }
                        return (React.createElement("div", { key: index, className: css.row }, fields.map(function (field, index) {
                            var _a;
                            return (React.createElement("div", { className: cn(css.item, (_a = {},
                                    _a[css.field_middleName] = field === 'middleName',
                                    _a)), key: index },
                                React.createElement(TravellerField, { fieldName: field })));
                        })));
                    })),
                    React.createElement("div", { className: css.container },
                        React.createElement("h2", { className: css.header }, t('Contacts')),
                        React.createElement("p", { className: css.description }, t('With the help of this data, we will be able to inform the fellow traveler about changes in booking, flight status, check-in time, etc. Optional data.')),
                        React.createElement("div", { className: cn(css.row, css.row_contacts) },
                            React.createElement("div", { className: css.item },
                                React.createElement(TravellerField, { fieldName: 'email' })),
                            React.createElement("div", { className: css.item },
                                React.createElement(TravellerField, { fieldName: 'phone' })))),
                    props.showPreferences && (React.createElement(Preferences, { Meal: ['Vegetarian menu', 'same another'], Seats: ['Window seat', 'Center seat'] })),
                    React.createElement("div", { className: css.container },
                        React.createElement("h2", { className: css.header }, t('Documents')),
                        React.createElement("p", { className: css.description }, t('Save the details of your internal or foreign passport, this will speed up the process of purchasing tickets.')),
                        React.createElement("div", { className: css.documents__container }, (_b = values['documents']) === null || _b === void 0 ? void 0 : _b.map(function (document, index) {
                            return (React.createElement(DocumentForm, { nationality: values['nationality'], document: document, validators: documentValidators, id: index, onRemove: function (id) {
                                    return form.mutators.documentsMutator({
                                        type: 'remove',
                                        index: id
                                    });
                                } }));
                        })),
                        React.createElement("div", { className: css.addWrapper, onClick: function () {
                                return form.mutators.documentsMutator({
                                    type: 'push'
                                });
                            } },
                            React.createElement(Button, { classes: {
                                    root: css.add
                                }, variant: "text" }, Add),
                            t('Add document'))),
                    React.createElement("div", { className: cn(css.controls) },
                        props.type === TravellerTypes.CompanionFilled && (React.createElement(React.Fragment, null,
                            React.createElement(Button, { className: css.remove, onClick: function () {
                                    openDeletionDialog();
                                }, variant: "text" },
                                DeleteIcon,
                                t('Remove passengers data')),
                            React.createElement(ModalConfirm, { isOpen: isDeletionDialogOpen, onConfirm: function () {
                                    closeDeletionDialog();
                                    props.onDelete();
                                }, onCancel: closeDeletionDialog, onClose: closeDeletionDialog, confirmBtn: t('Delete'), title: React.createElement("div", { className: css.deletion__header },
                                    React.createElement("div", { className: css.deletion__header__icon }, DeleteIcon),
                                    React.createElement("div", { className: css.deletion__header__title }, t('Are you sure you want to delete companion`s data?'))) }))),
                        React.createElement("div", { className: css.controls__buttons }, renderLoading(React.createElement(React.Fragment, null,
                            React.createElement(Button, { className: css.cancel, onClick: props.onClose, variant: "smallSecondary" }, t('Cancel')),
                            React.createElement(Tooltip, { title: t('An error occurred while executing the request, please try again'), open: openedErrorTooltip },
                                React.createElement(Button, { type: 'submit', className: css.save, variant: "smallThird" }, t('Save')))))))));
            } })));
};
export default TravellerForm;
