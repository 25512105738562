import { __assign } from "tslib";
import * as React from 'react';
import { DateMaskedInput } from '../../../DateMaskedInput/DateMaskedInput';
import Input from '../../../Input';
import { useField } from 'react-final-form';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
var DocExpireField = function (_a) {
    var name = _a.name, required = _a.required, validate = _a.validate, label = _a.label, isMobile = _a.isMobile;
    var field = useField(name, {
        validate: validate
    });
    var css = useTheme('DocumentForm').DocumentForm;
    var tDocuments = useTranslation('Passenger').t;
    React.useEffect(function () {
        if (!required) {
            field.input.onChange(null);
        }
    }, [required]);
    if (!required) {
        return null;
    }
    return (React.createElement(Input, __assign({}, field.input, { className: css.form__item, inputComponent: DateMaskedInput, TextFieldProps: {
            label: label,
            onChange: function (event) {
                field.input.onChange(event);
            },
            onBlur: function (event) {
                field.input.onBlur(event);
            },
            helperText: field.meta.touched && tDocuments(field.meta.error),
            error: field.meta.error && field.meta.touched
        }, variant: isMobile ? 'standard' : 'filled' })));
};
export default DocExpireField;
