import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { parse, format, eachDayOfInterval, startOfWeek, endOfWeek, isAfter, isSameDay } from 'date-fns';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { getSegments, hasSelectedSegments } from '../../store/segments/selectors';
import { API_DATE_FORMAT } from '../../../utils';
import DateSwitcher from '../DateSwitcher/DateSwitcher';
import ScheduleFlight from '../ScheduleFlight/ScheduleFlight';
import Locations from '../Locations/Locations';
import ResultsError from '../../../ResultsError';
var Results = function (_a) {
    var className = _a.className, schedule = _a.schedule, _b = _a.availableDates, availableDates = _b === void 0 ? [] : _b, onStartSearch = _a.onStartSearch, onSelect = _a.onSelect, onChangeDate = _a.onChangeDate;
    var theme = useTheme('CoreSchedule').Results;
    var t = useTranslation('Schedule').t;
    var segments = useSelector(getSegments);
    var hasSegments = useSelector(hasSelectedSegments);
    var startDate = parse(segments.date, API_DATE_FORMAT, new Date());
    var filteredFlights = useMemo(function () {
        var _a, _b;
        var availablesDays = eachDayOfInterval({
            start: startOfWeek(startDate, { weekStartsOn: 1 }),
            end: endOfWeek(startDate, { weekStartsOn: 1 })
        })
            .filter(function (date) { return isSameDay(date, new Date()) || isAfter(date, new Date()); })
            .map(function (date) { return format(date, 'EEEE'); });
        if ((_b = (_a = schedule === null || schedule === void 0 ? void 0 : schedule.flightDirections[0]) === null || _a === void 0 ? void 0 : _a.legs) === null || _b === void 0 ? void 0 : _b.length) {
            return schedule.flightDirections[0].legs.filter(function (flight) {
                return flight.weekdays.some(function (day) { return availablesDays.includes(day); });
            });
        }
        return [];
    }, [schedule === null || schedule === void 0 ? void 0 : schedule.flightDirections, startDate]);
    return (React.createElement("div", { className: cn(theme.container, className) },
        React.createElement("div", { className: theme.content },
            !schedule && !hasSegments && React.createElement(Locations, { departure: segments.departure, onSelect: onSelect }),
            schedule && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: theme.controls },
                    React.createElement(DateSwitcher, { date: startDate, availableDates: availableDates, onChangeDate: onChangeDate })),
                filteredFlights.length > 0 ? (React.createElement("div", { className: theme.flights }, filteredFlights.map(function (flight, index) { return (React.createElement(ScheduleFlight, { key: index, startDate: startDate, flight: flight, onClickDate: onStartSearch })); }))) : (React.createElement(ResultsError, { className: theme.error, header: t("We did not find flights to the selected cities"), body: t('Please select another city or change your search. If you would like to purchase a ticket for a specific city, please contact customer service.') })))))));
};
export default Results;
