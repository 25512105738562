import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Page404 from '../../../../Page404/Page404';
import Initial from './Initial/Initial';
import OrderWrapper from './OrderWrapper';
import { createDataLayer } from '../../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../../DataLayerAnalytics/OrderDataLayer/types';
import { useCurrentUser } from '../../../../CurrentUser/CurrentUser.hooks';
var Checkin = function () {
    createDataLayer(OrderType.Checkin);
    useCurrentUser();
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: "/", exact: true, component: Initial }),
        React.createElement(Route, { path: "/:id(\\d+)", render: function (_a) {
                var match = _a.match;
                return React.createElement(OrderWrapper, { orderId: match.params.id });
            } }),
        React.createElement(Route, { path: [
                "/search/:PNR([0-9\u0411\u0412\u0413\u0414\u041A\u041B\u041C\u041D\u041F\u0420\u0421\u0422\u0424\u0425\u0426\u0416\u0428A-Z]{6,6})?/:lastName([w\u0410-\u044FA-z]+)?",
                "/search/:PNR([0-9A-Z\u0410-\u042F]{13,13})?/:lastName([w\u0410-\u044FA-z]+)?",
                "/search/:PNR([0-9\u0411\u0412\u0413\u0414\u041A\u041B\u041C\u041D\u041F\u0420\u0421\u0422\u0424\u0425\u0426\u0416\u0428A-Z]{6,6})?/:lastName([w\u0410-\u044FA-z]+)?/submit",
                "/search/:PNR([0-9A-Z\u0410-\u042F]{13,13})?/:lastName([w\u0410-\u044FA-z]+)?/submit"
            ], exact: true, component: Initial }),
        React.createElement(Page404, { onClick: function () { return (window.location.href = '/'); } })));
};
export default Checkin;
