import { __assign, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaggageType } from '@websky/graphql';
import { useTheme } from '../../../../../theme';
import { getActualBaggage, getSegmentsInBaggageGroup, isCanModifyServices } from '../../../utils';
import { getDisabled } from '../../../store/selector';
import { setServices } from '../../../store/actions';
import PassengerBaggageRadio from '../PassengerBaggageRadio/PassengerBaggageRadio';
import PassengerBaggageCounter from '../PassengerBaggageCounter/PassengerBaggageCounter';
import BaggageCounter from '../../../../BaggageCounter';
var PassengerBaggageControl = function (_a) {
    var baggageServices = _a.baggageServices, passenger = _a.passenger, segmentId = _a.segmentId, isUnavailable = _a.isUnavailable;
    var theme = useTheme('UpgradeBaggageDialog').BaggagePassenger;
    var dispatch = useDispatch();
    var isDisabled = useSelector(getDisabled);
    var isAllSegments = !segmentId;
    var actualBaggage = useMemo(function () {
        return getActualBaggage(baggageServices, !isUnavailable ? segmentId : undefined, false);
    }, [baggageServices, segmentId, isUnavailable]);
    var segmentIds = useMemo(function () {
        var _a;
        if (!(actualBaggage === null || actualBaggage === void 0 ? void 0 : actualBaggage.segmentIds)) {
            return [];
        }
        if (isAllSegments) {
            return __spreadArray([], __read(getSegmentsInBaggageGroup(baggageServices)), false);
        }
        return (_a = actualBaggage.segmentIds.find(function (ids) { return ids.includes(segmentId); })) !== null && _a !== void 0 ? _a : [];
    }, [baggageServices, actualBaggage, segmentId, isAllSegments]);
    var isCanModify = useMemo(function () { return isCanModifyServices(baggageServices, passenger); }, [baggageServices, passenger]);
    var disabled = isDisabled || isUnavailable || !isCanModify || !(actualBaggage === null || actualBaggage === void 0 ? void 0 : actualBaggage.canBeAdded);
    var controlProps = {
        segmentId: segmentId,
        segmentIds: segmentIds,
        disabled: disabled,
        passengerId: passenger.id,
        baggages: baggageServices
    };
    var onChange = useCallback(function (type) { return function (items) {
        dispatch(setServices({ items: items, type: type }));
    }; }, [dispatch]);
    if (!actualBaggage) {
        return (React.createElement("div", { className: theme.counter_wrapper },
            React.createElement(BaggageCounter, { disabled: true })));
    }
    if ([BaggageType.BaggageExcess].includes(actualBaggage.type)) {
        return React.createElement(PassengerBaggageRadio, __assign({}, controlProps, { onChange: onChange('radio') }));
    }
    return React.createElement(PassengerBaggageCounter, __assign({}, controlProps, { onChange: onChange('counter') }));
};
export default PassengerBaggageControl;
