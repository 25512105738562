import { __assign } from "tslib";
import * as React from 'react';
import GenderSwitch from '../GenderSwitch/GenderSwitch';
import { Field, useForm } from 'react-final-form';
import { useTheme } from '../../../theme';
import Input from '../../../Input';
import { DateMaskedInput } from '../../../DateMaskedInput/DateMaskedInput';
import { TravellerFields } from '../../types';
import { useTranslation } from 'react-i18next';
import PhoneInput from '../../../PhoneInput';
import { Select } from '../../../Passenger/components/Field/Select/Select';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
var TravellerField = function (props) {
    var css = useTheme('TravellerForm').TravellerForm;
    var t = useTranslation('Traveller').t;
    var form = useForm();
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    if (props.fieldName === TravellerFields.Gender) {
        return (React.createElement(Field, { name: props.fieldName, render: function (_a) {
                var input = _a.input, meta = _a.meta;
                return (React.createElement(GenderSwitch, { className: css.sex, error: meta.touched && !meta.modified && t(meta.error), onChange: function (value) {
                        input.onChange(value);
                    }, value: input.value }));
            } }));
    }
    if (props.fieldName === TravellerFields.Nationality) {
        return (React.createElement(Field, { name: props.fieldName, render: function (_a) {
                var _b;
                var input = _a.input, meta = _a.meta;
                return (React.createElement(Select, { options: ((_b = props.options) === null || _b === void 0 ? void 0 : _b.map(function (option) { return ({
                        key: option.value,
                        value: option.label
                    }); })) || [], name: input.name, label: t(props.fieldName), onChange: function (key, event) {
                        input.onChange(event);
                        form.mutators.nationalityMutator();
                    }, value: input.value, className: css.nationality, variant: isMobile ? 'standard' : 'filled', error: meta.touched && meta.error }));
            } }));
    }
    if (props.fieldName === TravellerFields.BirthDate) {
        return (React.createElement(Field, { name: props.fieldName, type: "text", render: function (_a) {
                var input = _a.input, meta = _a.meta;
                return (React.createElement(Input, __assign({}, input, { className: css.birthday__input, inputComponent: DateMaskedInput, TextFieldProps: {
                        label: t(props.fieldName),
                        error: meta.error && meta.touched,
                        helperText: meta.touched && t(meta.error),
                        onChange: function (event) {
                            input.onChange(event);
                        },
                        onBlur: function (event) {
                            input.onBlur(event);
                        }
                    }, variant: isMobile ? 'standard' : 'filled' })));
            } }));
    }
    if (props.fieldName === TravellerFields.Phone) {
        return (React.createElement(Field, { name: "phone", type: "text", render: function (_a) {
                var input = _a.input, meta = _a.meta;
                return (React.createElement("div", { className: css.phone__input },
                    React.createElement(PhoneInput, { value: input.value, textFieldsProps: {
                            label: t(props.fieldName),
                            error: meta.error && meta.touched,
                            helperText: meta.touched && t(meta.error),
                            variant: isMobile ? 'standard' : 'filled',
                            onChange: function (event) {
                                input.onChange(event);
                            },
                            onBlur: function (event) {
                                input.onBlur(event);
                            }
                        } })));
            } }));
    }
    return (React.createElement(Field, { name: props.fieldName, type: "text", render: function (_a) {
            var input = _a.input, meta = _a.meta;
            return (React.createElement(Input, __assign({}, input, { variant: isMobile ? 'standard' : 'filled', TextFieldProps: {
                    label: t(props.fieldName),
                    error: meta.error && meta.touched,
                    helperText: meta.touched && t(meta.error),
                    onChange: function (event) {
                        input.onChange(event);
                    },
                    onBlur: function (event) {
                        input.onBlur(event);
                    }
                } })));
        } }));
};
export default TravellerField;
