import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import ModalConfirm from '../../../../../ModalConfirm';
var successIcon = (React.createElement("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("circle", { cx: "18", cy: "18", r: "18", fill: "#5FCA43" }),
    React.createElement("path", { d: "M11.1738 18.5147L15.5796 23.25L25.3488 12.75", stroke: "white", strokeWidth: "2.1", strokeLinecap: "round" })));
var Dialog = function (props) {
    var _a = __read(React.useState(props.open), 2), isOpen = _a[0], setOpen = _a[1];
    var _b = __read(React.useState(false), 2), isLoading = _b[0], setLoading = _b[1];
    var open = function () { return setOpen(true); };
    var close = function () { return setOpen(false); };
    var onAccept = function () {
        var accept = props.onAccept();
        if (typeof accept === 'object' && typeof accept['then'] === 'function') {
            setLoading(true);
            accept.then(close);
        }
        else {
            close();
        }
    };
    var onCancel = function () {
        props.onCancel();
        close();
    };
    var theme = useTheme('Exchange').Dialog;
    return (React.createElement(React.Fragment, null,
        props.children &&
            props.children({
                open: open,
                close: close,
                isOpen: isOpen
            }),
        React.createElement(ModalConfirm, { size: "small", isOpen: isOpen, onCancel: onCancel, isLoading: isLoading, cancelBtn: props.cancelText, hideCancelBtn: !props.cancelText, onClose: close, confirmBtn: props.acceptText, onConfirm: onAccept },
            React.createElement("div", { className: theme.content },
                React.createElement("div", { className: cn(theme.icon, props.iconClassName) }, successIcon),
                React.createElement("div", null, props.text)))));
};
export default Dialog;
