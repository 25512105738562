import { __assign } from "tslib";
import React from 'react';
import { useTheme } from '../../../theme';
import { Dialog } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { initI18n } from '../../../utils';
import classNames from 'classnames';
import { GrowTransition, SlideUpTransition } from '../Transitions';
import { useSwipeDown } from '../hooks';
import CloseButton from '../../../BaseComponents/Button/components/CloseButton/CloseButton';
initI18n('ModalX');
var ModalX = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'small' : _b, classes = _a.classes, children = _a.children, _c = _a.isOpen, isOpen = _c === void 0 ? false : _c, onClose = _a.onClose, _d = _a.scroll, scroll = _d === void 0 ? 'paper' : _d, title = _a.title, footer = _a.footer, ignoreClose = _a.ignoreClose, noPadding = _a.noPadding;
    var theme = useTheme('BaseComponents').ModalX;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var closeButton = onClose ? (React.createElement(CloseButton, { onClick: onClose, variant: "closePopup", classes: { root: classNames(theme.closeButton, classes === null || classes === void 0 ? void 0 : classes.closeButton) } })) : null;
    var _e = useSwipeDown({
        scroll: scroll,
        onClose: onClose,
        isMobile: isMobile,
        isOpen: isOpen
    }), handlers = _e.handlers, dialogRef = _e.dialogRef, contentRef = _e.contentRef, swipeBlockRef = _e.swipeBlockRef, swipeFxRef = _e.swipeFxRef, paperRef = _e.paperRef;
    var dialogHandlers = scroll === 'body' ? null : !ignoreClose ? __assign({}, handlers) : null;
    var swipeBlockHandlers = scroll === 'body' ? (!ignoreClose ? __assign({}, handlers) : null) : null;
    return (React.createElement(Dialog, __assign({ keepMounted: true, disableAutoFocus: true }, dialogHandlers, { ref: dialogRef, fullScreen: size === 'fullscreen', scroll: scroll, fullWidth: true, TransitionComponent: isMobile || size === 'fullscreen' ? SlideUpTransition : GrowTransition, PaperProps: {
            ref: paperRef
        }, open: isOpen, onClose: ignoreClose ? null : onClose, classes: {
            root: classNames(theme.root, classes === null || classes === void 0 ? void 0 : classes.root),
            container: theme.dialog_container,
            paper: classNames(theme.paper, theme[size], classes === null || classes === void 0 ? void 0 : classes.paper, size === 'fullscreen' ? theme.paper__fullScreen : null, noPadding ? theme.paper__noPadding : null),
            paperScrollBody: theme.paper_paperScrollBody
        } }),
        isMobile && onClose && !ignoreClose && (React.createElement("div", __assign({ ref: swipeBlockRef, className: theme.swipeBlock }, swipeBlockHandlers),
            React.createElement("div", { className: theme.swipeBlock_line, ref: swipeFxRef }))),
        !ignoreClose && closeButton,
        React.createElement("div", { className: theme.top_wrapper },
            title,
            React.createElement("div", { className: theme.content, ref: contentRef }, children)),
        footer));
};
export default ModalX;
