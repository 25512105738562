import { __awaiter, __generator } from "tslib";
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import recentSearchesSagas from './recentSearches/sagas';
import { REDIRECT_TO_FAST_SEARCH, SUBMIT_FORM } from './actions';
import { getSearchParameters } from './selectors';
import { createFastSearchURL } from '../utils';
import { SET_SEGMENTS, setLocation } from './segments/actions';
import { LocationsDocument } from '@websky/graphql';
import { LocationType } from '../types';
import { createClient } from '../../graphql-client';
import { SearchGoal, reachGoal } from '../../analytics';
import { isOfferHotels } from './offerHotels/selectors';
import passengersSaga from './passengers/sagas';
var getLocation = function (query) { return __awaiter(void 0, void 0, void 0, function () {
    var client, request;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                client = createClient();
                return [4 /*yield*/, client.query({
                        query: LocationsDocument,
                        variables: {
                            parameters: {
                                query: query
                            }
                        }
                    })];
            case 1:
                request = _b.sent();
                return [2 /*return*/, (_a = request === null || request === void 0 ? void 0 : request.data) === null || _a === void 0 ? void 0 : _a.LocationSuggestions];
        }
    });
}); };
function sendAnalyticsEvent() {
    var searchParameters, isOfferHotelsActive, from, to, goal;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getSearchParameters)];
            case 1:
                searchParameters = _a.sent();
                return [4 /*yield*/, select(isOfferHotels)];
            case 2:
                isOfferHotelsActive = _a.sent();
                from = searchParameters.segments[0];
                to = searchParameters.segments[searchParameters.segments.length - 1];
                goal = isOfferHotelsActive ? SearchGoal.SuccessfulSearchWithHotels : SearchGoal.SuccessfulSearchWithoutHotels;
                reachGoal(goal, {
                    funnel_purchase: {
                        airport_from: from.departure,
                        airport_to: to.arrival,
                        date_start: from.date,
                        date_end: to.date,
                        passenger_type: searchParameters.passengers.map(function (passenger) { return "".concat(passenger.count).concat(passenger.passengerType); }),
                        avia_operator: window.location.hostname
                    }
                });
                return [2 /*return*/];
        }
    });
}
function submitFormSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(SUBMIT_FORM, function (_a) {
                    var searchParameters;
                    var onSubmit = _a.payload;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, select(getSearchParameters)];
                            case 1:
                                searchParameters = _b.sent();
                                return [4 /*yield*/, call(sendAnalyticsEvent)];
                            case 2:
                                _b.sent();
                                onSubmit(searchParameters);
                                return [2 /*return*/];
                        }
                    });
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function redirectToFastSearchSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(REDIRECT_TO_FAST_SEARCH, function (_a) {
                    var searchParameters, fastSearchURL, _b, _c, _d, _e, _f;
                    var payload = _a.payload;
                    return __generator(this, function (_g) {
                        switch (_g.label) {
                            case 0: return [4 /*yield*/, select(getSearchParameters)];
                            case 1:
                                searchParameters = _g.sent();
                                return [4 /*yield*/, call(sendAnalyticsEvent)];
                            case 2:
                                _g.sent();
                                return [4 /*yield*/, delay(500)];
                            case 3:
                                _g.sent(); // timeout for analytics request
                                fastSearchURL = "".concat(payload.engineUrl, "#/search/").concat(createFastSearchURL(searchParameters));
                                if (!payload.hotelsUrl) return [3 /*break*/, 10];
                                _c = (_b = window).open;
                                if (!(payload.openOfferHotelsStrategy === 'newTab')) return [3 /*break*/, 5];
                                return [4 /*yield*/, payload.hotelsUrl];
                            case 4:
                                _d = _g.sent();
                                return [3 /*break*/, 6];
                            case 5:
                                _d = fastSearchURL;
                                _g.label = 6;
                            case 6:
                                _c.apply(_b, [_d, '_blank']);
                                _e = window.location;
                                if (!(payload.openOfferHotelsStrategy === 'currentTab')) return [3 /*break*/, 8];
                                return [4 /*yield*/, payload.hotelsUrl];
                            case 7:
                                _f = _g.sent();
                                return [3 /*break*/, 9];
                            case 8:
                                _f = fastSearchURL;
                                _g.label = 9;
                            case 9:
                                _e.href = _f;
                                return [3 /*break*/, 11];
                            case 10:
                                window.location.href = fastSearchURL;
                                _g.label = 11;
                            case 11: return [2 /*return*/];
                        }
                    });
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function handleSetSegments() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(SET_SEGMENTS, function (_a) {
                    var suggest, suggest;
                    var _b, _c, _d, _e, _f, _g, _h, _j;
                    var segments = _a.payload;
                    return __generator(this, function (_k) {
                        switch (_k.label) {
                            case 0:
                                if (!!!((_c = (_b = segments.simpleRouteSegment) === null || _b === void 0 ? void 0 : _b.arrival) === null || _c === void 0 ? void 0 : _c.iata)) return [3 /*break*/, 3];
                                return [4 /*yield*/, call(getLocation, segments.simpleRouteSegment.arrival.iata)];
                            case 1:
                                suggest = _k.sent();
                                if (!(((_e = (_d = suggest === null || suggest === void 0 ? void 0 : suggest[0]) === null || _d === void 0 ? void 0 : _d.cities) === null || _e === void 0 ? void 0 : _e[0].iata) === segments.simpleRouteSegment.arrival.iata)) return [3 /*break*/, 3];
                                return [4 /*yield*/, put(setLocation(suggest[0].cities[0], LocationType.Arrival))];
                            case 2:
                                _k.sent();
                                _k.label = 3;
                            case 3:
                                if (!!!((_g = (_f = segments.simpleRouteSegment) === null || _f === void 0 ? void 0 : _f.departure) === null || _g === void 0 ? void 0 : _g.iata)) return [3 /*break*/, 6];
                                return [4 /*yield*/, call(getLocation, segments.simpleRouteSegment.departure.iata)];
                            case 4:
                                suggest = _k.sent();
                                if (!(((_j = (_h = suggest === null || suggest === void 0 ? void 0 : suggest[0]) === null || _h === void 0 ? void 0 : _h.cities) === null || _j === void 0 ? void 0 : _j[0].iata) === segments.simpleRouteSegment.departure.iata)) return [3 /*break*/, 6];
                                return [4 /*yield*/, put(setLocation(suggest[0].cities[0], LocationType.Departure))];
                            case 5:
                                _k.sent();
                                _k.label = 6;
                            case 6: return [2 /*return*/];
                        }
                    });
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default function rootSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([
                    recentSearchesSagas(),
                    submitFormSaga(),
                    handleSetSegments(),
                    redirectToFastSearchSaga(),
                    passengersSaga()
                ])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
