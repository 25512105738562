import { __assign, __read, __spreadArray } from "tslib";
import { getTotalPrice } from '../../utils';
export var getAnimalTotalPrice = function (services, travellers, withoutUnconfirmed) {
    if (withoutUnconfirmed === void 0) { withoutUnconfirmed = false; }
    return getTotalPrice(services, function (service) {
        var allowedPassengerIds = new Set(service.allowedPassengers.reduce(function (acc, ids) { return __spreadArray(__spreadArray([], __read(acc), false), __read(ids), false); }, []));
        if (travellers.length) {
            return getTotalPrice(travellers, function (traveller) {
                var _a, _b, _c;
                if (allowedPassengerIds.has(traveller.id)) {
                    var selectedServices = ((_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(function (selectedService) {
                        return selectedService.serviceId === service.id &&
                            (!withoutUnconfirmed || (withoutUnconfirmed && selectedService.confirmedCount === 0));
                    })) || [];
                    return getTotalPrice(selectedServices, function (selectedService) { return (__assign(__assign({}, service.price), { amount: service.price.amount * selectedService.segmentIds.length })); });
                }
                return null;
            });
        }
        return null;
    });
};
