import { __read } from "tslib";
import { useCreateNewCompanionMutation, useSendPersonalDetailsMutation, useUpdateTravellerMutation } from '@websky/graphql';
export var useSubmitPersonalDetails = function () {
    var _a = __read(useSendPersonalDetailsMutation(), 1), sendPersonalDetails = _a[0];
    var submit = function (values) {
        return sendPersonalDetails({
            variables: {
                params: {
                    middleName: values['middleName'],
                    citizenship: values['nationality'],
                    lastName: values['lastName'],
                    firstName: values['firstName'],
                    birthDate: values['birthDate'],
                    email: values['email'],
                    phone: values['phone'],
                    gender: values['gender'],
                    documents: values['documents'],
                    loyaltyCards: values['loyaltyCards']
                }
            }
        });
    };
    return {
        submit: submit
    };
};
export var useCreateTraveller = function () {
    var _a = __read(useCreateNewCompanionMutation(), 1), addNewCompanion = _a[0];
    var submit = function (values) {
        return addNewCompanion({
            variables: {
                params: {
                    middleName: values['middleName'],
                    citizenship: values['nationality'],
                    lastName: values['lastName'],
                    firstName: values['firstName'],
                    birthDate: values['birthDate'],
                    email: values['email'],
                    phone: values['phone'],
                    gender: values['gender'],
                    documents: values['documents'],
                    loyaltyCards: values['loyaltyCards']
                }
            }
        });
    };
    return {
        submit: submit
    };
};
export var useUpdateTraveller = function () {
    var _a = __read(useUpdateTravellerMutation(), 1), updateTravellerProfile = _a[0];
    var submit = function (values, id) {
        var lastName = values.lastName, birthDate = values.birthDate, citizenship = values.citizenship, documents = values.documents, email = values.email, firstName = values.firstName, gender = values.gender, latinFirstName = values.latinFirstName, latinLastName = values.latinLastName, latinMiddleName = values.latinMiddleName, loyaltyCards = values.loyaltyCards, middleName = values.middleName, phone = values.phone;
        return updateTravellerProfile({
            variables: {
                params: {
                    id: id,
                    newParameters: {
                        latinMiddleName: latinMiddleName,
                        latinFirstName: latinFirstName,
                        lastName: lastName,
                        birthDate: birthDate,
                        citizenship: citizenship,
                        documents: documents,
                        email: email,
                        firstName: firstName,
                        gender: gender,
                        latinLastName: latinLastName,
                        loyaltyCards: loyaltyCards,
                        middleName: middleName,
                        phone: phone
                    }
                }
            }
        });
    };
    return {
        submit: submit
    };
};
