import { __assign, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { get, SEATS_RECOMMENDATION_SHOWN, set } from '../../../../cache';
import { MotivationGoal, reachGoal } from '../../../../analytics';
import { useMediaQuery } from 'react-responsive';
import { TABLET_MIDDLE_WIDTH } from '../../../../utils';
export var SeatsRecommendContext = React.createContext({});
var STICKY_HEADER_HEIGHT = 80;
var SeatsRecommendation = function (_a) {
    var _b, _c, _d, _e;
    var confirmRecommendedSeatsWithoutDialog = _a.confirmRecommendedSeatsWithoutDialog, orderId = _a.orderId, travellers = _a.travellers, segments = _a.segments, runServiceRequest = _a.runServiceRequest, setSeat = _a.setSeat, fillOrder = _a.fillOrder, children = _a.children, segmentIndex = _a.segmentIndex, passengerId = _a.passengerId, isEnabled = _a.isEnabled, seatMap = _a.seatMap;
    var _f = __read(useState(false), 2), isOpen = _f[0], setIsOpen = _f[1];
    var rowsMap = {};
    var isMobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    var firstSegmentId = segments === null || segments === void 0 ? void 0 : segments[0].id;
    var firstPreselected = (_e = (_d = (_c = (_b = travellers === null || travellers === void 0 ? void 0 : travellers[0]) === null || _b === void 0 ? void 0 : _b.preselectedServices) === null || _c === void 0 ? void 0 : _c.seats) === null || _d === void 0 ? void 0 : _d.find(function (seat) { return seat.segment.id === firstSegmentId; })) === null || _e === void 0 ? void 0 : _e.seat;
    var onOpen = function () {
        setIsOpen(true);
        set("".concat(SEATS_RECOMMENDATION_SHOWN, "_").concat(orderId, "_").concat(segmentIndex), true);
    };
    useEffect(function () {
        if (confirmRecommendedSeatsWithoutDialog) {
            onConfirm();
        }
    }, [confirmRecommendedSeatsWithoutDialog]);
    useEffect(function () {
        setIsOpen(false);
        if (!(get("".concat(SEATS_RECOMMENDATION_SHOWN, "_").concat(orderId, "_").concat(segmentIndex)) ||
            get("".concat(SEATS_RECOMMENDATION_SHOWN, "_").concat(orderId, "_ALL"))) &&
            isEnabled &&
            !confirmRecommendedSeatsWithoutDialog) {
            if (travellers.some(function (traveller) { return traveller.preselectedServices.seats.length; })) {
                onOpen();
            }
        }
    }, [isEnabled, travellers, segmentIndex]);
    var needToScrollToRecommendedSeat = typeof confirmRecommendedSeatsWithoutDialog === 'boolean' && confirmRecommendedSeatsWithoutDialog;
    var onDisagree = useCallback(function () {
        setIsOpen(false);
        reachGoal(MotivationGoal.RefuseProposedSeat);
    }, []);
    var onConfirm = useCallback(function () {
        var seat = document.querySelector("[data-seat-number=\"".concat(firstPreselected === null || firstPreselected === void 0 ? void 0 : firstPreselected.number, "\"]"));
        var STICKY_HEADER_HEIGHT = 80;
        var HALF_OF_WINDOW = window.innerHeight / 2;
        setIsOpen(false);
        if (runServiceRequest) {
            var request = travellers.map(function (_a) {
                var preselectedServices = _a.preselectedServices, id = _a.id;
                return {
                    passengerId: id,
                    seats: preselectedServices.seats.map(function (_a) {
                        var seat = _a.seat, segment = _a.segment;
                        return ({
                            isConfirmed: false,
                            row: parseInt(seat.row),
                            letter: seat.letter,
                            seat: null,
                            segment: segment,
                            type: null,
                            product: null
                        });
                    })
                };
            });
            runServiceRequest(request, fillOrder);
        }
        else if (setSeat) {
            travellers.map(function (_a) {
                var preselectedServices = _a.preselectedServices, id = _a.id;
                preselectedServices.seats
                    .filter(function (it) {
                    if (confirmRecommendedSeatsWithoutDialog) {
                        return true;
                    }
                    else {
                        return it.segment.id === segmentIndex.toString();
                    }
                })
                    .map(function (seat) {
                    setSeat([
                        {
                            number: seat.seat.number,
                            row: seat.seat.row,
                            price: seat.seat.price,
                            letter: seat.seat.letter,
                            service: null,
                            rfisc: null,
                            isAvailable: null,
                            isAisle: null,
                            isExistent: null,
                            isSmoking: null,
                            isRecline: null,
                            seatService: null,
                            seatServices: null,
                            services: [],
                            restrictions: null,
                            permissions: []
                        }
                    ], id, seat.segment.id);
                });
            });
        }
        if (firstPreselected && seat && window.hasOwnProperty('scrollTo') && needToScrollToRecommendedSeat) {
            window.scrollTo({
                top: seat.getBoundingClientRect().top - STICKY_HEADER_HEIGHT - HALF_OF_WINDOW,
                behavior: 'smooth'
            });
        }
        if (confirmRecommendedSeatsWithoutDialog) {
            set("".concat(SEATS_RECOMMENDATION_SHOWN, "_").concat(orderId, "_ALL"), true);
        }
        reachGoal(MotivationGoal.ConfirmProposedSeat);
    }, [travellers, fillOrder, confirmRecommendedSeatsWithoutDialog, needToScrollToRecommendedSeat, segmentIndex]);
    travellers === null || travellers === void 0 ? void 0 : travellers.forEach(function (_a) {
        var preselectedServices = _a.preselectedServices, passengerId = _a.id;
        return preselectedServices.seats.forEach(function (seat) {
            var _a, _b;
            var id = seat.segment.id;
            if (rowsMap[id]) {
                rowsMap[id].price.amount += seat.seat.price.amount;
                rowsMap[id].seats[passengerId] = seat;
                rowsMap[id].seatsMap[seat.seat.number] = seat.seat;
            }
            else {
                rowsMap[id] = {
                    price: __assign({}, seat.seat.price),
                    seats: (_a = {},
                        _a[passengerId] = seat,
                        _a),
                    seatsMap: (_b = {},
                        _b[seat.seat.number] = seat.seat,
                        _b)
                };
            }
        });
    });
    var scrollToPreselectedSeat = function () {
        var _a, _b;
        var seat = (_b = (_a = rowsMap[segmentIndex.toString()]) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b[passengerId];
        var HALF_OF_WINDOW = !isMobile ? window.innerHeight / 2 + STICKY_HEADER_HEIGHT : 0;
        if (seat === null || seat === void 0 ? void 0 : seat.seat) {
            var seatDOM = document.querySelector("[data-seat-number=\"".concat(seat.seat.number, "\"]"));
            window.scrollTo({
                top: seatDOM.getBoundingClientRect().top - HALF_OF_WINDOW + window.scrollY,
                behavior: 'smooth'
            });
        }
    };
    React.useEffect(function () {
        if (isOpen) {
            setTimeout(scrollToPreselectedSeat);
        }
    }, [segmentIndex, passengerId, isOpen]);
    return (React.createElement(SeatsRecommendContext.Provider, { value: {
            isOpen: isOpen,
            onOpen: onOpen,
            onConfirm: onConfirm,
            onDisagree: onDisagree,
            rowsMap: rowsMap,
            isEnabled: isEnabled,
            availableSeatsCount: seatMap.segments[segmentIndex].availableSeatsCount
        } }, children));
};
export default SeatsRecommendation;
