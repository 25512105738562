import * as React from 'react';
import { Flight } from '../../Icons';
import { API_DATE_FORMAT, format, removeDotsFromDate } from '../../utils';
import ServiceSummary from '../../ServiceSummary';
import ServiceSummaryOption from '../../ServiceSummary/components/ServiceSummaryOption';
import { parse } from 'date-fns';
import { useContext } from 'react';
import { CartContext } from '../Cart';
import { useTranslation } from 'react-i18next';
import ServiceSummaryDivider from '../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import { CheckoutSteps } from '../../MobileStepbar/types';
var Flights = function (_a) {
    var _b;
    var flight = _a.flight, renderService = _a.renderService;
    var cartContext = useContext(CartContext);
    var t = useTranslation('Cart').t;
    var groupOptions = React.useMemo(function () {
        return flight.segmentGroups.map(function (segment) {
            var firstSegment = segment.segments[0], lastSegment = segment.segments[segment.segments.length - 1];
            var departureDate = parse(firstSegment.departure.date, API_DATE_FORMAT, new Date());
            return {
                header: "".concat(firstSegment.departure.airport.city.name, " \u2014 ").concat(lastSegment.arrival.airport.city.name),
                headerIcon: Flight,
                items: [
                    {
                        id: 'date',
                        name: "".concat(removeDotsFromDate(format(departureDate, 'dd MMM, EEEEEE')), " \u2022 ").concat(firstSegment.departure.time, " - ").concat(lastSegment.arrival.time)
                    },
                    {
                        id: 'fare',
                        name: segment.fareFamily.title,
                        price: segment.price
                    }
                ]
            };
        });
    }, [flight]);
    var group = {
        header: t('Flights'),
        icon: Flight,
        totalPrice: (_b = cartContext.totalPrices) === null || _b === void 0 ? void 0 : _b.flights
    };
    var content = (React.createElement(React.Fragment, null,
        React.createElement(ServiceSummaryDivider, null, groupOptions.map(function (groupOption) { return (React.createElement(ServiceSummaryOption, { groupOption: groupOption, key: groupOption.header })); }))));
    if (!groupOptions.length) {
        return null;
    }
    if (renderService) {
        return renderService(CheckoutSteps.Flights, group, content);
    }
    return React.createElement(ServiceSummary, { group: group }, content);
};
export default Flights;
