import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useTheme } from '../../../../../theme';
import ModalConfirm from '../../../../../ModalConfirm/components/ModalConfirm';
var Warning = function (_a) {
    var open = _a.open, onClose = _a.onClose, redirectURL = _a.redirectURL;
    var css = useTheme('Checkout').Warning;
    var t = useTranslation('Checkout').t;
    var onClick = useCallback(function () { return (window.location.href = redirectURL); }, [redirectURL]);
    return (React.createElement(ModalConfirm, { size: "medium", isOpen: open, onClose: onClose, title: t('Next step'), confirmBtn: t('Continue'), onConfirm: onClick, hideCancelBtn: true },
        React.createElement("div", { className: css.text }, t('Now you will be redirected to the previous version of the site. There you can choose ancillary services.'))));
};
export default Warning;
