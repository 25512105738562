import { __assign } from "tslib";
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import Dialog from '../Service/components/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { initI18n } from '../utils';
import Warnings from '../Warnings';
import Input from '../BaseComponents/Input';
import { InputVariant } from '../BaseComponents/Input/types';
import MediaQuery from '../MediaQuery/MediaQuery';
import SlideBottom from '../SlideBottom';
import Button from '../BaseComponents/Button';
initI18n('SecureAccess');
function SecureAccess(_a) {
    var isOpen = _a.isOpen, onSubmit = _a.onSubmit, onResend = _a.onResend, isLoading = _a.isLoading, showError = _a.showError;
    var t = useTranslation('SecureAccess').t;
    var theme = useTheme('SecureAccess').SecureAccess;
    var handleSubmit = function (values) {
        onSubmit(values.secret2fa);
    };
    var form = (React.createElement(Form, { onSubmit: handleSubmit, render: function (_a) {
            var handleSubmit = _a.handleSubmit, valid = _a.valid, touched = _a.touched;
            return (React.createElement("div", { className: theme.content },
                React.createElement("h4", { className: theme.header }, t('Add booking')),
                React.createElement(Warnings, { textClassName: theme.warningText, iconClassName: theme.warningIcon, contentClassName: theme.warningContent, iconTextClassName: theme.iconText, warnings: [
                        t('Enter your access confirmation number. For security reasons, it was sent to the email you provided when creating the order.')
                    ] }),
                React.createElement("form", { onSubmit: handleSubmit, className: theme.form },
                    React.createElement(Field, { validate: function (value) { var _a; return (((_a = value === null || value === void 0 ? void 0 : value.trim()) === null || _a === void 0 ? void 0 : _a.length) > 0 ? undefined : t('Required')); }, name: 'secret2fa', render: function (_a) {
                            var input = _a.input;
                            return (React.createElement(Input, { FormHelperTextProps: { classes: { error: theme.helperText } }, className: theme.input, disabled: isLoading, variant: InputVariant.Underline, InputProps: __assign({}, input), error: showError, helperText: showError ? t('The entered code does not match the code from the email') : null, label: t('Ticket / order number from e-mail') }));
                        } }),
                    React.createElement(Button, { variant: "action", className: theme.button, isLoading: isLoading, disabled: touched && !valid, onClick: function (e) { return handleSubmit(); } }, t('Continue')))));
        } }));
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: 'mobile' },
            React.createElement(Dialog, { dialogClassName: theme.paper, content: form, isOpen: isOpen, onClose: null, dialogType: 'popup', withoutControls: true })),
        React.createElement(MediaQuery, { maxWidth: 'mobile' },
            React.createElement(SlideBottom, { className: theme.slideBottom, isOpen: isOpen, onClose: function () { return null; } }, form))));
}
export default SecureAccess;
